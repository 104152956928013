import { h } from 'hyperapp';
import cc from 'classcat';
import './index.less';
const iconMap = {
  success: {
    class: 'pop-success',
    text: 'check'
  },
  warn: {
    class: 'pop-warn',
    text: 'error_outline'
  },
  error: {
    class: '',
    text: ''
  }
};
export default ((
/** @type {{state: Components.PopComponentProps}} */
{
  state,
  actions
}) => {
  const okText = state.okText || '确定';
  return h("div", {
    class: "mdui-dialog pop-dialog mdui-dialog-open",
    id: "pop",
    oncreate: element => actions.onCreate({
      element
    })
  }, h("div", {
    class: "mdui-dialog-title pop-title"
  }, state.type !== 'info' ? h("i", {
    class: cc(['mdui-icon', 'material-icons', 'pop-icon', iconMap[state.type].class])
  }, iconMap[state.type].text) : null, state.title), h("div", {
    class: "mdui-dialog-content pop-content",
    oncreate: element => {
      element.innerHTML = state.contentHTML;
    },
    onupdate: element => {
      element.innerHTML = state.contentHTML;
      actions.onUpdate();
    }
  }), h("div", {
    class: "mdui-dialog-actions pop-actions"
  }, state.cancelText ? h("button", {
    class: "mdui-btn mdui-ripple pop-cancel",
    onclick: () => actions.cancel(state.onCancel)
  }, state.cancelText) : null, h("button", {
    class: "mdui-btn mdui-ripple pop-submit" // TODO: 如果不加key, okText变化后html不会更新
    ,
    key: okText,
    disabled: state.isLoading,
    onclick: () => actions.ok(state.onOk)
  }, okText)));
});