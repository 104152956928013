import { h } from 'hyperapp';
import cc from 'classcat';
import { Link } from 'hyperapp-router';
import { fullPath, isPathArticle, isPathArticles, isPathInbox, isPathIndex, isPathNotifications, isPathQuestion, isPathQuestions, isPathTopic, isPathTopics, isPathUser, isPathUsers, isPathPublish } from '~/utils/path';
import './index.less'; // const { site_icp_beian, site_gongan_beian } = window.G_OPTIONS;
// const site_gongan_beian_code = site_gongan_beian
//   ? site_gongan_beian.match(/\d+/)[0]
//   : '';

const Item = ({
  url,
  icon,
  title,
  active,
  count,
  key
}) => h(Link, {
  to: fullPath(url),
  class: cc(['mdui-list-item', 'mdui-ripple', {
    'mdui-list-item-active': active
  }]) // 因为mdui-drawer点击item的时候, 为了显示效果, 会动态修改dom结构
  // 如果刚好在patch时dom结构发生了修改, 有可能会导致patch不正确.
  // 加key是为了防止上述情况发生(即重新整个渲染而非调用dom的api)
  ,
  key: key
}, h("i", {
  class: "mdui-list-item-icon mdui-icon material-icons"
}, icon), h("div", {
  class: "mdui-list-item-content"
}, title), count ? h("span", {
  className: "mdui-list-item-count"
}, count > 99 ? '...' : count) : null);

export default (({
  user,

  /** interviewee, */
  notificationCount = 0
}) => h("div", {
  class: "mc-drawer mdui-drawer mdui-drawer-close "
}, h("div", {
  class: "mdui-list"
}, h(Item, {
  url: "/",
  icon: "forum",
  title: "\u53CD\u9988\u4E0E\u95EE\u9898",
  active: isPathIndex()
}), user ? [h("div", {
  class: "mdui-divider",
  key: "0"
}), h(Item, {
  url: "/publish",
  icon: "settings",
  title: "\u53D1\u8868\u7BA1\u7406",
  active: isPathPublish(),
  key: "1"
})] : null, user ? h(Item, {
  url: "/notifications",
  icon: "notifications",
  title: "\u901A\u77E5",
  active: isPathNotifications(),
  count: notificationCount,
  key: `notifiction_${notificationCount}`
}) : null)));