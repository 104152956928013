import extend from 'mdui.jq/es/functions/extend';
import editorState from '~/components/editor/state';
import settingToastState from '~/components/setting-edit/state';
import settingReasonsSelector from '~/components/setting-edit/sub/fail-reason-selector/state.js';
import settingTopicSelector from '~/components/editor/components/topic-selector/state.js';
/** @type {Stores.ReviewDetailState} */

const state = {
  question: {},
  answer: {},
  comment: {},
  isShowQuestion: false,
  reviewInfo: {
    // 审核状态, 0未审核,1.已审核,2审核失败
    status: -1,
    // 审核失败详情
    failReply: {
      username: '',
      userAvatar: '',
      datetime: 0,
      content: ''
    }
  },
  isPublishEditingReview: false,
  isConfirming: false,
  reviewReasons: [],
  currentReviewId: '',
  commentTopType: ''
};
export default extend({}, state, editorState, settingToastState, settingReasonsSelector, settingTopicSelector);