import { h } from 'hyperapp';
import './index.less';
import WeiboSvg from '~/svg/weibo.svg';
import { requireStatic } from '~/utils/requireStatic';

const Item = ({
  name,
  svg,
  onClick
}) => h("div", {
  class: "mdui-list-item mdui-ripple",
  onclick: onClick
}, h("img", {
  src: requireStatic(svg)
}), h("div", {
  class: "mdui-list-item-content"
}, name));

export default (({
  actions
}) => h("div", {
  oncreate: element => actions.onCreate({
    element
  }),
  key: "share-dialog",
  class: "mc-share-dialog mdui-dialog"
}, h("div", {
  class: "mdui-dialog-title"
}, h("span", null, "\u5206\u4EAB"), h("button", {
  class: "mdui-btn mdui-btn-icon mdui-ripple",
  onclick: actions.close
}, h("i", {
  class: "mdui-icon material-icons mdui-text-color-theme-icon"
}, "close"))), h("div", {
  class: "mdui-dialog-content"
}, h("div", {
  class: "mdui-list"
}, h(Item, {
  name: "\u5FAE\u535A",
  svg: WeiboSvg,
  onClick: actions.shareToWeibo
})))));