import extend from 'mdui.jq/es/functions/extend';
import editorState from '~/components/editor/state';
const as = {
  topics_pagination: null,
  topics_loading: false,
  questions_recent_data: [],
  questions_recent_pagination: null,
  questions_recent_loading: false,
  questions_popular_data: [],
  questions_popular_pagination: null,
  questions_popular_loading: false,
  articles_recent_data: [],
  articles_recent_pagination: null,
  articles_recent_loading: false,
  articles_popular_data: [],
  articles_popular_pagination: null,
  articles_popular_loading: false,
  editor_open: true,
  // 最后访问的提问ID
  last_visit_id: 0,
  question: [],
  // 是否正在发送
  submitting: false,
  // 问题类型
  topics: [],
  // 问题类型id
  topic_id: 0,
  order: '-update_time',
  // 分页
  pagination: {
    page: 1
  },
  // 是否正在加载
  loading: false,
  topicLoading: false,
  // 自动保存首页输入框的前缀
  auto_save_key: 'page-index-editor'
};
export default extend(as, editorState);