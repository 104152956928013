import { h } from 'hyperapp';
import $ from 'mdui.jq';
import mdui from 'mdui';
import { Link } from 'hyperapp-router';
import { fullPath, isPathArticle, isPathArticles, isPathInbox, isPathIndex, isPathNotifications, isPathQuestion, isPathQuestions, isPathTopic, isPathTopics, isPathUser, isPathUsers } from '~/utils/path';
import { emit } from '~/utils/pubsub';
import './index.less';
import SearchBar from './components/search-bar/view.jsx';
import SearchIcon from './components/search-icon/view.jsx';

const Title = ({
  interviewee
}) => h("div", {
  class: "title"
}, isPathIndex() ? "\u9996\u9875" : isPathQuestions() || isPathQuestion() ? "\u95EE\u7B54" : isPathArticles() || isPathArticle() ? "\u6587\u7AE0" : isPathTopics() || isPathTopic() ? "\u8BDD\u9898" : isPathUser() && interviewee ? interviewee.username : isPathInbox() ? "\u79C1\u4FE1" : isPathNotifications() ? "\u901A\u77E5" : null);

export default (() => h("div", {
  class: "toolbar mdui-toolbar"
}, h("button", {
  class: "drawer mdui-btn mdui-btn-icon mdui-ripple",
  oncreate: element => {
    const $drawer = $('.mc-drawer');
    const drawer = new mdui.Drawer($drawer, {
      swipe: true
    });
    $drawer.data('drawerInstance', drawer);
    $(element).on('click', () => drawer.toggle());
  }
}, h("i", {
  class: "mdui-icon material-icons"
}, "menu")), h(Link, {
  class: "headline",
  to: fullPath('/')
}, window.G_OPTIONS.site_name)));