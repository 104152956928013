import { h } from 'hyperapp';
import './index.less';
import { removeCookie } from '~/utils/cookie';
import MenuItem from './menu-item/view.jsx';
export default (({
  menuDate
}) => {
  // 点击下拉菜单选项
  const clickMenu = text => {
    if (text === '退出') {
      removeCookie('session_id', '.xk.design');
      window.location.href = '/';
    } else {
      const isTesting = window.location.host.includes('test');
      window.location.href = `//${isTesting ? 'test2.xk.design' : 'xk.design'}/files/recent?f=setting`;
    }
  };

  return h("div", {
    class: "menu-container avatar-menu"
  }, h("div", {
    class: "menu-triangle"
  }), menuDate.map((item, index) => {
    return h(MenuItem, {
      key: index,
      text: item.text,
      class: "item",
      onClick: () => clickMenu(item.text)
    });
  }));
});