import { h } from 'hyperapp';
import './index.less';
import TopicChip from '~/components/editor/components/topic-chip/view.jsx';

const TopicSelectorItem = ({
  editor_selected_topics,
  topicSelectorOpen,
  topicSelectorRemoveOne,
  id
}) => h("div", {
  class: "editor-topics"
}, editor_selected_topics.length ? h("div", {
  class: "chip-wrapper"
}, editor_selected_topics.map((topic, selectedIndex) => h(TopicChip, {
  topic: topic,
  onRemove: () => {
    topicSelectorRemoveOne(selectedIndex);
  }
}))) : null, !editor_selected_topics.length ? h("span", {
  class: "placeholder",
  title: "\u70B9\u51FB\u6DFB\u52A0\u8BDD\u9898",
  onclick: () => topicSelectorOpen(id)
}, "\u8BF7\u81F3\u5C11\u9009\u62E9 1 \u4E2A\u95EE\u9898\u5206\u7C7B") : null, h("button", {
  class: "add mdui-btn mdui-btn-icon mdui-btn-dense mdui-ripple",
  title: "\u70B9\u51FB\u6DFB\u52A0\u8BDD\u9898",
  onclick: () => {
    topicSelectorOpen(id);
  }
}, h("i", {
  class: "mdui-icon material-icons mdui-text-color-theme-icon"
}, "add")));

export default (({
  editor_selected_topics,
  topicSelectorOpen,
  id,
  topicSelectorRemoveOne
}) => {
  return h("div", {
    class: "topic-select-container"
  }, h("div", {
    class: "topic-select-title"
  }, "\u6DFB\u52A0\u5206\u7C7B"), h("div", {
    class: "topic-select"
  }, h("span", {
    class: "label"
  }, "\u5206\u7C7B"), h(TopicSelectorItem, {
    editor_selected_topics: editor_selected_topics,
    topicSelectorOpen: topicSelectorOpen,
    topicSelectorRemoveOne: topicSelectorRemoveOne,
    id: id
  })));
});