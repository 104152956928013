import { h } from 'hyperapp';
import './index.less';
export default (({
  title,
  showChildren,
  onToggle
}, children) => {
  return h("div", {
    className: "mc-review-detail-question-collapser"
  }, h("div", {
    className: "mc-review-detail-question-collapser-containner"
  }, h("div", {
    className: "title-header"
  }, title), showChildren ? children : null), h("div", {
    className: "toggle-container"
  }, h("span", {
    onclick: () => onToggle(!showChildren)
  }, showChildren ? '收起问题' : '展开问题', h("i", {
    class: "mdui-icon material-icons"
  }, showChildren ? 'keyboard_arrow_up' : 'keyboard_arrow_down'))));
});