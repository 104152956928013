import { h } from 'hyperapp';
import mdui from 'mdui';
import './index.less';
import { emit } from '~/utils/pubsub';
import { getCommentDetail } from '~/apis/comments';
import ListHeader from '~/components/list-header/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Tab from '~/components/tab/view.jsx';
import { ContentListItem } from '~/components/list-item/view.jsx';
export default (({
  state,
  actions
}) => h("div", {
  class: "contexts mdui-card"
}, h(Tab, {
  items: [{
    name: '提问',
    hash: 'questions',
    count: state.interviewee ? state.interviewee.question_count : 0
  }, {
    name: '回答',
    hash: 'answers',
    count: state.interviewee ? state.interviewee.answer_count : 0
  }, {
    name: '评论',
    hash: 'comments',
    count: state.interviewee ? state.interviewee.comment_count : 0
  }]
}), state.tabs.map(tabName => {
  const pagination = state[`${tabName}_pagination`];
  const data = state[`${tabName}_data`];
  const order = state[`${tabName}_order`];
  const isLoading = state[`${tabName}_loading`];
  const isEmpty = !isLoading && !data.length && pagination;
  const isLoaded = !isLoading && pagination && pagination.page === pagination.pages;
  return () => h("div", {
    id: tabName
  }, tabName === 'questions' ? h(ListHeader, {
    show: !isEmpty,
    title: `共 ${pagination ? pagination.total : 0} 个提问`,
    disabled: isLoading,
    currentOrder: order,
    key: tabName,
    orders: [{
      order: '-update_time',
      name: '更新时间（从晚到早）'
    }, {
      order: 'update_time',
      name: '更新时间（从早到晚）'
    }],
    onChangeOrder: actions.changeOrder
  }) : null, tabName === 'comments' ? h(ListHeader, {
    show: !isEmpty,
    title: `共 ${pagination ? pagination.total : 0} 个评论`,
    disabled: isLoading,
    currentOrder: order,
    key: `user-${tabName}`,
    orders: [{
      order: '-create_time',
      name: '发布时间（从晚到早）'
    }, {
      order: 'create_time',
      name: '发布时间（从早到晚）'
    }, {
      order: '-vote_count',
      name: '最热门'
    }],
    onChangeOrder: actions.changeOrder
  }) : null, tabName === 'answers' ? h(ListHeader, {
    show: !isEmpty,
    title: `共 ${pagination ? pagination.total : 0} 个回答`,
    disabled: isLoading,
    currentOrder: order,
    key: tabName,
    orders: [{
      order: '-create_time',
      name: '发布时间（从晚到早）'
    }, {
      order: 'create_time',
      name: '发布时间（从早到晚）'
    }, {
      order: '-vote_count',
      name: '最热门'
    }],
    onChangeOrder: actions.changeOrder
  }) : null, data.length ? h("div", {
    class: "item-list"
  }, tabName === 'questions' ? data.map(question => {
    const item = {
      id: question.question_id,
      title: question.title,
      description: question.content_rendered,
      updateTime: question.answer_time || question.create_time,
      updateTimeDesc: question.answer_time ? '回复于' : '发布于',
      replys: `${question.answer_count} 个回答`
    };
    return h(ContentListItem, {
      item: item,
      path: `/questions/${question.question_id}`,
      last_visit_id: state.last_visit_question_id,
      onClick: () => actions.afterItemClick(question)
    });
  }) : null, tabName === 'comments' ? data.map(comment => {
    const item = {
      id: comment.comment_id,
      title: comment.content,
      description: '',
      updateTime: comment.create_time,
      updateTimeDesc: '发布于',
      replys: '',
      question_id: comment.question_id
    };
    return h(ContentListItem, {
      item: item,
      path: "",
      last_visit_id: state.last_visit_article_id,
      onClick: e => {
        e.preventDefault();

        if (comment.answer_id) {
          emit('comments_dialog_open', {
            question_id: comment.question_id,
            answer_id: comment.answer_id,
            commentable_type: 'answer',
            commentable_id: comment.answer_id,
            isReadonlyInDialog: true,
            isTargetUpdate: true
          });
        } else {
          emit('comments_dialog_open', {
            question_id: comment.question_id,
            commentable_type: 'question',
            commentable_id: comment.commentable_id,
            isReadonlyInDialog: true,
            isTargetUpdate: true
          });
        }

        actions.afterItemClick(comment);
      }
    });
  }) : null, tabName === 'answers' ? data.map(answer => {
    const item = {
      id: answer.answer_id,
      title: answer.relationships.question.title,
      description: answer.content_rendered,
      updateTime: answer.create_time,
      updateTimeDesc: '发布于',
      replys: `${answer.comment_count} 条评论`
    };
    return h(ContentListItem, {
      item: item,
      path: `/questions/${answer.question_id}/answers/${answer.answer_id}`,
      last_visit_id: state.last_visit_question_id,
      onClick: () => actions.afterItemClick(answer)
    });
  }) : null) : null, h(Empty, {
    show: isEmpty,
    title: `该用户没有发表${// eslint-disable-next-line no-nested-ternary
    tabName === 'questions' ? '提问' : tabName === 'comments' ? '评论' : '回答'}`
  }), h(Loading, {
    show: isLoading
  }), h(Loaded, {
    show: isLoaded
  }));
})));