import '~/initializer/polyfill';
import { app } from 'hyperapp';
import { location } from 'hyperapp-router';
import $ from 'mdui.jq';
import { emit } from '~/utils/pubsub';
import '~/initializer/index';
import actions from '~/initializer/actions';
import state from '~/initializer/state';
import view from '~/initializer/view.jsx';
import listener from '~/initializer/listener';
import './main.less';
import { requireStatic } from './utils/requireStatic'; // import { withLogger } from '@hyperapp/logger';
// 批量导入svg资源

const requireAll = requireContext => {
  requireContext.keys().map(requireContext);
};

const req = require.context('~/svg', false, /\.svg$/);

$(() => {
  document.body.innerHTML = ''; // window.app = withLogger(app)(state, actions, view, document.body);

  window.app = app(state, actions, view, document.body);
  listener(window.app);
  location.subscribe(window.app.location);
  requireAll(req); // 读取当前登陆用户

  const user = window.G_USER;
  const cssURL = requireStatic(require('~/static/image/favicon.png'));
  $(`<link rel="shortcut icon" type="images/x-icon" href="${cssURL}">`).appendTo('head');

  if (user) {
    emit('user_update', user);
    window.G_USER = null;
  }
});