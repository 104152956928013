import { h } from 'hyperapp';
import './index.less';
import Contexts from '~/pages/publish/components/contexts/view.jsx';
import DetailDialog from './components/detail-dialog/view.jsx';
export default ((state, actions) => ({
  match
}) => {
  return h("div", {
    class: "mdui-container publish-container",
    key: match.url,
    oncreate: actions.onCreate
  }, h(Contexts, {
    state: state,
    actions: actions
  }), h(DetailDialog, {
    state: state,
    actions: actions
  }));
});