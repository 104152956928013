import mdui from 'mdui';
import $ from 'mdui.jq';
import extend from 'mdui.jq/es/functions/extend';
import commonActions from '~/utils/actionsAbstract';
/** @type {Components.MdUiDialog} */

let $pop;
const as = {
  onCreate: ({
    element
  }) => {
    $(element).mutation();

    if (!$pop) {
      $pop = new mdui.Dialog('#pop');
    }
  },
  onUpdate: () => {
    $pop.handleUpdate();
  },

  /**
   *
   * @param {Components.PopComponentProps} props
   * @returns
   */
  open: props => (state, actions) => {
    const cancelText = props.cancelText || '';
    const okText = props.okText || '';
    const type = props.type || 'info';
    actions.setState(extend(props, {
      cancelText,
      okText,
      type
    }));
    $pop = new mdui.Dialog('#pop');
    $pop.open();
  },

  /**
   * 取消按钮
   */
  cancel: callback => {
    if (typeof callback === 'function') {
      callback($pop);
    } else {
      $pop.close();
    }
  },
  ok: callback => (state, actions) => {
    if (typeof callback === 'function') {
      callback($pop, {
        get: () => actions.getState(),
        set: v => actions.setState(v)
      });
    } else {
      $pop.close();
    }
  }
};
export default extend(as, commonActions);