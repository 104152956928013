import { h } from 'hyperapp';
import './index.less';
export default (({
  text,
  onClick
}) => {
  return h("div", {
    class: "menu-item-container",
    style: {
      borderTop: text === '退出' ? '1px solid #ebebeb' : ''
    },
    onclick: onClick
  }, h("div", {
    class: "menu-wrap menu-center"
  }, text));
});