import $ from 'mdui.jq';
import mdui from 'mdui';
import extend from 'mdui.jq/es/functions/extend';
import { generate as generateCaptcha } from 'mdclub-sdk-js/es/CaptchaApi';
import commonActions from '~/utils/actionsAbstract';
import { emit } from '~/utils/pubsub';
import apiCatch from '~/utils/errorHandler';
let dialog;
let $name;
let $captchaCode;
const as = {
  /**
   * 响应输入框输入事件
   */
  onInput: e => {
    const input = e.target;
    return {
      [input.name]: input.value,
      [`${input.name}_msg`]: ''
    };
  },

  /**
   * 初始化
   */
  onCreate: props => {
    const $element = $(props.element).mutation();
    dialog = new mdui.Dialog($element, {
      modal: true,
      history: false
    });
    $name = $element.find('[name="name"]');
    $captchaCode = $element.find('[name="captcha_code"]');
    $element.on('open.mdui.dialog', () => {
      $name[0].focus();
    });
  },

  /**
   * 打开登陆框
   */
  open: () => {
    dialog.open();
  },

  /**
   * 关闭登录界面对话框
   */
  close: () => {
    if (dialog) {
      dialog.close();
    }
  },

  /**
   * 打开注册对话框
   */
  toRegister: () => {
    dialog.close();
    emit('register_open');
  },

  /**
   * 打开重置密码对话框
   */
  toReset: () => {
    dialog.close();
    emit('reset_open');
  },

  /**
   * 刷新验证码
   */
  refreshCaptcha: () => (_, actions) => {
    generateCaptcha().then(({
      data: {
        captcha_token,
        captcha_image
      }
    }) => {
      actions.setState({
        captcha_token,
        captcha_image,
        captcha_code: ''
      });
      setTimeout(() => {
        $captchaCode[0].focus();
      });
    }).catch(apiCatch);
  }
};
export default extend(as, commonActions);