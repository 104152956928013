import './index.less';
import { h } from 'hyperapp';
import cc from 'classcat';
import { requireStatic } from '~/utils/requireStatic';
export default (({
  avatar,
  nickname,
  username,
  isShowName = true,
  userNameClassName,
  imgClassName,
  containerClassName,
  onClick
}) => {
  // eslint-disable-next-line prefer-destructuring
  const nicknameFirstLetter = Array.from(nickname.trim().toUpperCase() || ' ')[0];
  const isShowDefaultAvatar = !avatar && (!nickname || nickname.indexOf('****') > 0);
  const isShowLabel = !avatar && nickname && nickname.indexOf('****') < 0;
  const isShowAvatar = !!avatar;
  const currentUserName = nickname || username;
  return (// containerClassName
    h("div", {
      class: cc(['xk-avatar', containerClassName]),
      onclick: onClick
    }, isShowAvatar ? h("img", {
      class: cc([imgClassName, 'avatar']),
      src: avatar
    }) : null, isShowLabel ? h("span", {
      class: cc([imgClassName, 'p-avatar-text p-avatar p-component p-avatar-circle']),
      key: nicknameFirstLetter
    }, nicknameFirstLetter) : null, isShowDefaultAvatar ? h("img", {
      class: cc([imgClassName, 'avatar']),
      src: requireStatic(require('../../static/image/headshot.png'))
    }) : null, isShowName ? h("span", {
      class: cc([userNameClassName, 'nickname'])
    }, currentUserName) : null)
  );
});