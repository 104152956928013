import { h } from 'hyperapp';
import HeaderIcon from './sub/header-icon/view.jsx';
import './index.less';
export default (({
  title,
  setting_maximize,
  // settingMinimize,
  settingMaximize,
  settingClose
}) => h("div", {
  class: "quesion-edit-header"
}, h("div", {
  class: "header-title"
}, title), h("div", {
  class: "header-actions"
}, h(HeaderIcon, {
  iconTitle: setting_maximize ? '退出全屏' : '全屏模式',
  icon: setting_maximize ? 'fullscreen_exit' : 'fullscreen',
  onClick: settingMaximize
}), h(HeaderIcon, {
  iconTitle: "\u4FDD\u5B58\u4E3A\u8349\u7A3F\u5E76\u5173\u95ED",
  icon: "close",
  onClick: settingClose
}))));