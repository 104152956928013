import { h } from 'hyperapp';
import cc from 'classcat';
import Loading from '~/components/loading/view.jsx';
import Content from '~/pages/review-detail/components/content/view.jsx';
import { timeFormat } from '~/utils/time';
import './index.less';
export default function (
/** @type {{state: Stores.PublishPageState, actions: Stores.PublishPageActions}} */
{
  state,
  actions
}) {
  const {
    detailDialog
  } = state;
  return h("div", {
    class: "mc-publish-detail-dialog mdui-dialog",
    oncreate: element => actions.detailDialogCreate({
      element
    }),
    onupdate: actions.detailDialogUpdate
  }, h(Loading, {
    show: detailDialog.isLoading
  }), detailDialog.isLoaded ? h(Content, {
    username: detailDialog.username,
    userAvatar: detailDialog.avatar,
    datetimeStr: timeFormat(detailDialog.datetime, 'MM月DD日'),
    content: detailDialog.content,
    title: detailDialog.title
  }) : null, h("div", {
    class: cc(['mdui-dialog-actions', {
      'mdui-hidden': !detailDialog.isLoaded
    }])
  }, h("button", {
    class: "mdui-btn question-detail-button mdui-ripple",
    onclick: actions.detailDialogClose
  }, "\u5173\u95ED")));
}