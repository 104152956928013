import { h } from 'hyperapp';
import { location } from 'hyperapp-router';
import mdui from 'mdui';
import extend from 'mdui.jq/es/functions/extend';
import { getLocationQuery } from '~/utils/func';
import { timeFormat } from '~/utils/time';
import { fullPath } from '~/utils/path';
import { emit } from '~/utils/pubsub';
import Nav from '~/components/nav/view.jsx';
import Content from './components/content/view.jsx';
import QuestionCollapser from './components/question-collapser/view.jsx';
import ReviewFailReply from '~/components/review-fail-reply/view.jsx';
import Button from '~/components/button-base/view.jsx';
import Loading from '~/components/loading/view.jsx';
import Editor from '~/components/editor/view.jsx';
import SettingEdit from '~/components/setting-edit/view.jsx';
import QuestionInfo from '~/components/setting-edit/sub/question-info-line/view.jsx';
import FailReasonSelector from '~/components/setting-edit/sub/fail-reason-selector/view.jsx';
import ReviewTips from '~/components/setting-edit/sub/review-tips/view.jsx';
import Topics from '~/components/setting-edit/sub/topics/view.jsx';
import TopicSelector from '~/components/editor/components/topic-selector/view.jsx';
import Empty from '~/components/empty/view.jsx';
import './index.less';
/**
 *
 * @param {Stores.ReviewDetailState} state
 * @param {Stores.ReviewDetailActions} actions
 * @returns
 */

export default ((state, actions) => ({
  match
}) => {
  const routeQuery = getLocationQuery();
  const detailType = routeQuery.type;
  const reviewStatus = Number(routeQuery.state);
  const id = Number(routeQuery.id);
  /** @type {Stores.ReviewDetailState['question'] | Stores.ReviewDetailState['answer'] | Stores.ReviewDetailState['comment']} */

  const currentDetail = state[detailType];
  const isLoadedReviewFailInfo = state.reviewInfo.status === 2;
  const shouldLoadRelativeQuestion = !!routeQuery.question_id;
  const shouldLoadRelativeCommentTop = !shouldLoadRelativeQuestion && detailType === 'comment';
  const needToConfirmByAdmin = routeQuery.f === 'admin' && reviewStatus === 0;
  const canSettingsByAdmin = routeQuery.f === 'admin' && reviewStatus === 1;
  const commentTopData = state[state.commentTopType];

  const handleCollaspeChange = isShowQuestion => {
    actions.setState({
      isShowQuestion
    });
  };

  const handleReviewEdit = ({
    title,
    content
  }) => {
    actions.editReviewBy({
      type: detailType,
      id,
      title,
      content
    }).then(() => {
      actions.editorClose();
      mdui.snackbar('更新成功');
      gotoReviewPage(false); // eslint-disable-line no-use-before-define
    }).catch(err => {
      mdui.snackbar(err.message);
    });
  };

  const getReviewInfoByStatus = status => {
    if (needToConfirmByAdmin) {
      return null;
    }

    if (status === 0) {
      return {
        text: '审核中',
        statusType: 'warning'
      };
    }

    if (status === 1) {
      return {
        text: '审核成功',
        statusType: 'success'
      };
    } // if (status === 2) {
    //   return {
    //     text: '审核不通过',
    //     statusType: 'fail',
    //   };
    // }


    return null;
  };

  const getEditorTitle = () => {
    if (detailType === 'question') {
      return '编辑提问';
    }

    if (detailType === 'answer') {
      return '编辑回答';
    }

    if (detailType === 'comment') {
      return '编辑评论';
    }

    return '';
  };

  const gotoReviewPage = (useRouter = true) => {
    const url = fullPath('/publish#reviews');

    if (useRouter) {
      location.actions.go(url);
    } else {
      window.location.href = url;
    }
  };

  const clickDeleteReview = () => {
    const title = currentDetail.title || currentDetail.content_markdown;
    const specTitle = `“${title.length < 12 ? title : `${title.slice(0, 10)}...${title.slice(-2)}`}”`;
    const typeList = {
      answer: '回答',
      question: '问题',
      comment: '评论'
    };
    emit('pop_open', {
      title: '是否删除？',
      contentHTML: `是否删除${specTitle}${typeList[detailType]}？`,
      type: 'warn',
      cancelText: '取消',

      onOk($pop, $props) {
        $props.set({
          isLoading: true,
          okText: '正在删除'
        });
        actions.deleteReviewBy({
          type: detailType,
          id
        }).then(() => {
          $pop.close();
          mdui.snackbar('删除成功');
          setTimeout(() => {
            if (window.history.state) {
              window.history.back();
            } else {
              gotoReviewPage(false);
            }
          }, 100);
        }).catch(err => {
          mdui.snackbar(err.message);
        }).finally(() => {
          $props.set({
            isLoading: false,
            okText: '确定'
          });
        });
      }

    });
  };

  const loadPageData = () => {
    let detailTask;

    if (detailType === 'question') {
      detailTask = () => actions.loadQuestion({
        questionId: id,
        reviewStatus
      });
    } else if (detailType === 'answer') {
      detailTask = () => actions.loadAnswer({
        answerId: id,
        reviewStatus
      });
    } else if (detailType === 'comment') {
      detailTask = () => actions.loadComment({
        commentId: id,
        reviewStatus
      });
    }

    actions.setState({
      currentReviewId: `${detailType}_${id}_${reviewStatus}`
    });

    if (detailTask) {
      detailTask().catch(err => {
        if ([202001, 203001, 204001].includes(err.code)) {
          actions.setState({
            [detailType]: extend({}, state[detailType], {
              isNotFound: true,
              hasLoaded: true
            })
          });
        }

        mdui.snackbar(err.message);
      });
    }

    if (shouldLoadRelativeQuestion) {
      actions.loadQuestion({
        questionId: routeQuery.question_id
      });
    } else if (shouldLoadRelativeCommentTop) {
      actions.loadCommentRelativeTopData({
        commentId: id
      });
    }

    actions.loadReviewStatus({
      reviewType: detailType,
      censorId: id
    });
  };

  return h("div", {
    className: "mc-review-detail",
    key: match.url,
    oncreate: () => {
      emit('route_update');

      if (state.currentReviewId === `${detailType}_${id}_${reviewStatus}`) {
        return;
      }

      actions.resetState();
      loadPageData();

      if (needToConfirmByAdmin) {
        const {
          topics
        } = window.app.getState().index;

        if (topics.length === 0) {
          window.app.index.loadTopic();
        }

        actions.loadReasonList();
      }
    }
  }, h("div", {
    className: "g-flex g-justify-content-between g-align-items-center"
  }, h(Nav, {
    path: '/publish#reviews'
  }), !needToConfirmByAdmin ? h("div", null, !currentDetail.isNotFound ? h(Button, {
    className: "btn-review-del",
    width: 90,
    text: "\u5220\u9664",
    onClick: clickDeleteReview
  }) : null, isLoadedReviewFailInfo && currentDetail.editable ? h(Button, {
    width: 90,
    type: "primary",
    text: "\u4FEE\u6539",
    onClick: () => actions.editorOpen()
  }) : null) : needToConfirmByAdmin ? h("div", null, h(Button, {
    className: "btn-review-del",
    width: 90,
    text: "\u4E0D\u901A\u8FC7",
    disabled: state.isConfirming,
    onClick: () => {
      actions.setState({
        setting_open: true,
        currentStatus: 2,
        title: '不通过'
      });
    }
  }), h(Button, {
    width: 90,
    type: "primary",
    text: "\u901A\u8FC7",
    disabled: state.isConfirming,
    onClick: () => {
      actions.setState({
        setting_open: true,
        currentStatus: 1,
        title: '通过'
      });
    }
  })) : canSettingsByAdmin ? h("div", null, h(Button, {
    className: "btn-review-del",
    width: 90,
    text: "\u5220\u9664",
    onClick: clickDeleteReview
  }), h(Button, {
    width: 90,
    type: "info",
    text: "\u8BBE\u7F6E",
    onClick: () => {
      actions.setState({
        setting_open: true
      });
      actions.setState({
        isSetting: true,
        currentStatus: 1,
        title: '设置'
      });
    }
  })) : null), isLoadedReviewFailInfo && !needToConfirmByAdmin ? h(ReviewFailReply, {
    key: "review-faily-reply",
    username: state.reviewInfo.failReply.username,
    userAvatar: state.reviewInfo.failReply.userAvatar,
    replyDatetime: state.reviewInfo.failReply.datetime,
    replyContent: state.reviewInfo.failReply.content,
    editable: currentDetail.editable
  }) : null, shouldLoadRelativeQuestion && state.question.hasLoaded ? h(QuestionCollapser, {
    title: state.question.title,
    showChildren: state.isShowQuestion,
    onToggle: handleCollaspeChange
  }, h(Content, {
    className: "relative-question-content ",
    key: 'relative-question-content',
    username: state.question.username,
    userAvatar: state.question.userAvatar,
    datetimeStr: timeFormat(state.question.datetime, 'MM月DD日'),
    content: state.question.content
  })) : shouldLoadRelativeCommentTop && commentTopData && commentTopData.hasLoaded ? [state.commentTopType === 'question' ? h(QuestionCollapser, {
    title: commentTopData.title,
    showChildren: state.isShowQuestion,
    onToggle: handleCollaspeChange,
    key: "0"
  }, h(Content, {
    className: "relative-comment-top-content",
    key: 'relative-comment-top-content',
    username: commentTopData.username,
    userAvatar: commentTopData.userAvatar,
    datetimeStr: timeFormat(commentTopData.datetime, 'MM月DD日'),
    content: commentTopData.content
  })) : null, state.commentTopType === 'answer' ? h(Content, {
    className: "relative-comment-top-content",
    key: 'relative-comment-top-content',
    username: commentTopData.username,
    userAvatar: commentTopData.userAvatar,
    datetimeStr: timeFormat(commentTopData.datetime, 'MM月DD日'),
    content: commentTopData.content
  }) : null] : null, currentDetail.hasLoaded ? [currentDetail.isNotFound ? h(Empty, {
    show: true,
    title: "\u539F\u6587\u5DF2\u88AB\u522A\u9664",
    key: "0"
  }) : null, !currentDetail.isNotFound ? h(Content, {
    key: 'detail-content',
    username: currentDetail.username,
    userAvatar: currentDetail.userAvatar,
    datetimeStr: timeFormat(currentDetail.datetime, 'MM月DD日'),
    title: detailType === 'question' ? currentDetail.title : undefined,
    content: currentDetail.content,
    reviewInfo: getReviewInfoByStatus(state.reviewInfo.status)
  }) : null] : null, h(Loading, {
    show: state.isLoading
  }), currentDetail.hasLoaded ? h(Editor, {
    id: "review-detail-editor",
    title: getEditorTitle(),
    withTitle: detailType === 'question',
    withTopics: false,
    onSubmit: handleReviewEdit,
    publishing: state.isPublishEditingReview,
    isComment: detailType === 'comment',
    content: currentDetail.content,
    state: state,
    actions: actions,
    editor_title: currentDetail.title,
    questionTitle: detailType === 'question' ? currentDetail.title : ''
  }) : null, state.setting_open ? [h(SettingEdit, {
    setting_open: state.setting_open,
    setting_maximize: state.setting_maximize,
    setting_minimize: state.setting_minimize,
    title: state.title,
    cancel: actions.cancel,
    submit: () => actions.submit({
      type: detailType,
      id
    }),
    settingMaximize: actions.settingMaximize,
    settingMinimize: actions.settingMinimize,
    settingClose: actions.cancel,
    id: "setting-edit",
    key: "0"
  }, h(QuestionInfo, {
    avatar: state[detailType].userAvatar,
    title: detailType === 'answer' ? state[detailType].question_title : state[detailType].title,
    content: detailType === 'comment' ? state[detailType].content : state[detailType].content_markdown,
    type: detailType,
    isContainImg: state[detailType].content.indexOf('<img>') !== -1,
    isContainLink: state[detailType].content.indexOf('<a href>') !== -1
  }, !state.isSetting ? h(ReviewTips, {
    review_status: state.currentStatus
  }) : null), state.currentStatus === 2 ? [h(FailReasonSelector, {
    font_reason_id: state.font_reason_id,
    img_reason_id: state.img_reason_id,
    other_reason_id: state.other_reason_id,
    font_selections: state.font_selections,
    img_selections: state.img_selections,
    other_selections: state.other_selections,
    onChangeFontSelector: actions.onChangeFontSelector,
    onChangeImgSelector: actions.onChangeImgSelector,
    onChangeOtherSelector: actions.onChangeOtherSelector,
    key: "0"
  }), h("div", {
    class: "checkbox-container",
    key: "1"
  }, h("label", {
    class: "mdui-checkbox"
  }, h("input", {
    type: "checkbox",
    onchange: () => actions.setState({
      checkbox_review_status: 3
    })
  }), h("i", {
    class: "mdui-checkbox-icon"
  }), "\u8BF7\u4FEE\u6539\uFF0C\u91CD\u65B0\u53D1\u5E03"))] : null, state.currentStatus === 1 && state.isSetting ? h(Topics, {
    id: "setting-edit",
    editor_selected_topics: state.editor_selected_topics,
    topicSelectorOpen: actions.topicSelectorOpen,
    topicSelectorRemoveOne: actions.topicSelectorRemoveOne
  }) : null), h(TopicSelector, {
    state: state,
    actions: actions,
    key: "1"
  })] : null);
});