import { h } from 'hyperapp';
import MenuSelection from '~/components/menu-selection/view.jsx';
import './index.less';
export default (({
  label,
  selections,
  activeId,
  onChange,
  type
}) => h("div", {
  class: "fail-reason-item-container"
}, h("div", {
  class: "fail-reason-item-label"
}, label), h(MenuSelection, {
  selections: selections,
  activeId: activeId,
  onChange: onChange,
  className: 'fail-reason-item',
  key: type
})));