import { h } from 'hyperapp';
import { richText } from '~/utils/html';
import TagStatus from '~/components/tag-status/view.jsx';
import './index.less';
import Avatar from '~/components/avatar/view.jsx';
/**
 * @param {Components.ReviewDetail.ContentComponentProps} param0
 */

export default (({
  className = '',
  username,
  userAvatar,
  datetimeStr,
  title,
  content,
  reviewInfo,
  key
}) => {
  let nickname;

  if (username) {
    nickname = username.indexOf('xkid_') < 0 ? username : '';
  }

  return h("div", {
    key: key,
    className: `mc-review-detail-content ${className}`
  }, title ? h("div", {
    className: "title-header"
  }, title) : null, reviewInfo ? h("div", {
    key: "review-status",
    className: "review-status"
  }, h(TagStatus, {
    text: reviewInfo.text,
    status: reviewInfo.statusType
  })) : null, h("div", {
    className: "g-flex g-justify-content-between g-align-items-center"
  }, h("div", {
    className: "g-flex g-flex-1 g-justify-content-between g-align-items-center"
  }, h("div", {
    className: "g-flex g-justify-content-between g-align-items-center"
  }, h(Avatar, {
    imgClassName: 'user-img',
    avatar: userAvatar,
    nickname: nickname,
    username: username
  })), datetimeStr ? h("span", null, datetimeStr) : null)), h("div", {
    key: content,
    className: "content mdui-typo",
    oncreate: richText(content)
  }));
});