export default {
  // 文字原因选中id
  font_reason_id: 0,
  // 图片原因选中id，
  img_reason_id: 0,
  // 其他原因选中
  other_reason_id: 0,
  // 文字原因列表
  font_selections: [{
    id: 0,
    text: '请选择'
  }],
  // 图片原因
  img_selections: [{
    id: 0,
    text: '请选择'
  }],
  // 其他原因列表
  other_selections: [{
    id: 0,
    text: '请选择'
  }]
};