import { h } from 'hyperapp';
import cc from 'classcat';
import $ from 'mdui.jq';
import { COMMON_IMAGE_UPLOAD_FAILED } from 'mdclub-sdk-js/es/errors';
import Editor from 'mdui.editor/es/index';
import './index.less';
import TopicChip from './components/topic-chip/view.jsx';
import { requireStatic } from '~/utils/requireStatic';
export default (({
  id,
  // 唯一ID
  title,
  // 头部显示的名称
  withTitle = false,
  // 是否包含标题
  withTopics = false,
  // 是否包含话题选择
  onSubmit,
  // 点提交按钮的函数
  publishing,
  // 是否正在提交内容
  isIndex = false,
  // 用这个字段判断是否让文本聚焦
  state,
  // 包含 stateAbstract 中的 editorState 和 topicSelectorState
  actions,
  // 包含 actionsAbstract 中的 editorActions 和 topicSelectorActions
  className,
  editor_index_minimize,
  // 首页编辑器放大缩小
  bodyClassName,
  isKeyboard,
  content = '',
  editor_title = '',
  isComment = false
}) => {
  let returnEditor; // 按键常量
  // 判断编辑器标题文本

  const isNullByTitle = () => {
    return $('.editor-title[placeholder="请输入问题标题"]').length > 0 && !$('.editor-title[placeholder="请输入问题标题"]').val();
  }; // 判断编辑器内容文本


  const isNullByContent = () => {
    return !$('.editor-content[placeholder="请输入问题内容"]').find('figure').length && !$('.editor-content[placeholder="请输入问题内容"]').children().text();
  };

  const HeaderIcon = ({
    iconTitle,
    icon,
    onClick
  }) => h("i", {
    class: "mdui-icon material-icons",
    title: iconTitle,
    onclick: onClick
  }, icon);

  const IndexHeaderIconBySvg = ({
    svgSrc,
    onClick
  }) => h("img", {
    class: "index-svg-icon",
    src: svgSrc,
    onclick: onClick
  });

  const Header = ({
    isUp
  }) => h("div", {
    class: "header",
    onclick: actions.editorIndexMinimize ? actions.editorIndexMinimize : ''
  }, h("div", {
    class: "header-title"
  }, title), h("div", {
    class: "header-actions"
  }, !isKeyboard ? [h(HeaderIcon, {
    iconTitle: state.editor_maximize ? '退出全屏' : '全屏模式',
    icon: state.editor_maximize ? 'fullscreen_exit' : 'fullscreen',
    onClick: actions.editorMaximize,
    key: "0"
  }), h(HeaderIcon, {
    iconTitle: "\u4FDD\u5B58\u4E3A\u8349\u7A3F\u5E76\u5173\u95ED",
    icon: "close",
    onClick: actions.editorClose,
    key: "1"
  })] : null, isKeyboard ? h(IndexHeaderIconBySvg, {
    svgSrc: isUp ? requireStatic(require('../../svg/expand_less.svg')) : requireStatic(require('../../svg/expand_more.svg')),
    onClick: e => {
      actions.editorIndexMinimize(e);
    }
  }) : null));

  const Title = () => h("input", {
    class: "editor-title mdui-text-color-theme-text",
    placeholder: "\u8BF7\u8F93\u5165\u95EE\u9898\u6807\u9898",
    required: "required",
    maxLength: "80",
    value: editor_title || window.localStorage.getItem(`${state.auto_save_key}-title`),
    oninput: event => {
      window.localStorage.setItem(`${state.auto_save_key}-title`, event.target.value);
    }
  });

  const Topics = () => h("div", {
    class: "editor-topics"
  }, state.editor_selected_topics.length ? h("div", {
    class: "chip-wrapper"
  }, state.editor_selected_topics.map((topic, selectedIndex) => h(TopicChip, {
    topic: topic,
    onRemove: () => {
      actions.topicSelectorRemoveOne(selectedIndex);
    }
  }))) : null, !state.editor_selected_topics.length ? h("span", {
    class: "placeholder",
    title: "\u70B9\u51FB\u6DFB\u52A0\u8BDD\u9898",
    onclick: actions.topicSelectorOpen
  }, "\u8BF7\u81F3\u5C11\u9009\u62E9 1 \u4E2A\u8BDD\u9898") : null, h("button", {
    class: "add mdui-btn mdui-btn-icon mdui-btn-dense mdui-ripple",
    title: "\u70B9\u51FB\u6DFB\u52A0\u8BDD\u9898",
    onclick: actions.topicSelectorOpen
  }, h("i", {
    class: "mdui-icon material-icons mdui-text-color-theme-icon"
  }, "add")));

  const SubmitButton = () => h("button", {
    class: "submit mdui-btn mdui-btn-raised mdui-color-theme",
    disabled: publishing,
    onclick: event => {
      const $dialog = $(event.target).parents('.mc-editor');

      if (isComment) {
        onSubmit({
          content: $dialog.find('.comment_textarea').val()
        });
        return;
      }

      const editor = $dialog.data('editor-instance');
      onSubmit({
        title: $dialog.find('.editor-title').val(),
        content: editor.getHTML()
      });
    }
  }, publishing ? '发送中' : '发送');

  const createEditor = element => {
    const $element = $(element);
    const $toolbar = $element.find('.editor-toolbar');
    const $content = $element.find('.editor-content');
    const editor = new Editor($toolbar, $content, {
      onClearDrafts: () => {
        $('.mdui_editor-toolbar-menu-clear_drafts').addClass('disabled-draft');

        if (withTitle) {
          window.localStorage.removeItem(`${state.auto_save_key}-title`);
        }

        if (withTopics) {
          window.localStorage.removeItem(`${state.auto_save_key}-topics`);
        }

        if (isIndex) {
          $('.editor-title[placeholder="请输入问题标题"]')[0].focus();
        }

        if ($(element).find('.editor')) {
          return;
        }

        actions.editorClose();
      },
      placeholder: '请输入问题内容',
      autoSave: !!state.auto_save_key,
      autoSaveKey: `${state.auto_save_key}-content`,
      imageUploadMaxSize: 0,
      imageUploadUrl: `${window.G_API}/images`,
      imageUploadName: 'image',
      imageUploadResponseTransform: response => {
        if (response.code === COMMON_IMAGE_UPLOAD_FAILED) {
          response.message = response.extra_message;
        }

        if (response.code === 0) {
          response.data.url = response.data.urls.release;
          $('.mdui_editor-toolbar-menu-clear_drafts').removeClass('disabled-draft');
        }

        return response;
      }
    }); // 保存编辑器实例到元素中

    $element.data('editor-instance', editor);
    return editor;
  };

  const activeDraftButtonByContent = () => {
    if (!$('.mdui_editor-toolbar-menu-clear_drafts').hasClass('disabled-draft')) {
      return;
    }

    $('.mdui_editor-toolbar-menu-clear_drafts').removeClass('disabled-draft');
  };

  const disabledDraftButtonByTitle = () => {
    if ($('.mdui_editor-toolbar-menu-clear_drafts').hasClass('disabled-draft')) {
      return;
    }

    if (isNullByTitle() && isNullByContent()) {
      $('.mdui_editor-toolbar-menu-clear_drafts').addClass('disabled-draft');
    }
  };

  const backSpaceDisabledDraftButtonByContent = e => {
    if ($('.mdui_editor-toolbar-menu-clear_drafts').hasClass('disabled-draft')) {
      return;
    }

    if ((e.keyCode === 8 || e.keyCode === 46) && $('.editor-title[placeholder="请输入问题标题"]').length > 0 && $('.editor-title[placeholder="请输入问题标题"]').val()) {
      return;
    }

    if ((e.keyCode === 8 || e.keyCode === 46) && isNullByContent()) {
      $('.mdui_editor-toolbar-menu-clear_drafts').addClass('disabled-draft');
    }
  };

  const backSpaceDisabledDraftButtonByTitle = e => {
    if ($('.mdui_editor-toolbar-menu-clear_drafts').hasClass('disabled-draft')) {
      return;
    }

    if ((e.keyCode === 8 || e.keyCode === 46) && isNullByTitle() && isNullByContent()) {
      $('.mdui_editor-toolbar-menu-clear_drafts').addClass('disabled-draft');
    }
  };

  const disabledDraftButtonByContent = e => {
    e.stopPropagation();

    if ($('.mdui_editor-toolbar-menu-clear_drafts').hasClass('disabled-draft')) {
      return;
    }

    if (!isNullByTitle()) {
      return;
    }

    if ($('.editor-content[placeholder="请输入问题内容"]').find('figure')) {
      return;
    }

    if (isNullByContent()) {
      $('.mdui_editor-toolbar-menu-clear_drafts').addClass('disabled-draft');
    }
  };

  const activeDraftButtonByTitle = () => {
    if (!$('.mdui_editor-toolbar-menu-clear_drafts').hasClass('disabled-draft')) {
      return;
    }

    $('.mdui_editor-toolbar-menu-clear_drafts').removeClass('disabled-draft');
  };

  return h("div", {
    class: cc(['mc-editor', className, {
      'mdui-hidden': !state.editor_open,
      maximize: state.editor_maximize,
      minimize: state.editor_minimize,
      'index-minimize': editor_index_minimize,
      'with-title': withTitle,
      'with-topics': withTopics
    }]),
    id: id,
    key: `mc-editor-${id}`,
    oncreate: element => {
      if (isComment) {
        return;
      }

      returnEditor = createEditor(element);
      $('.editor-title').on('input', activeDraftButtonByTitle);
      $('.editor-content').on('input', activeDraftButtonByContent);
      $('.editor-content').on('focus', disabledDraftButtonByContent);
      $('.editor-content').on('keydown', backSpaceDisabledDraftButtonByContent);
      $('.editor-content').on('keyup', backSpaceDisabledDraftButtonByContent);
      $('.editor-title').on('keydown', backSpaceDisabledDraftButtonByTitle);
      $('.editor-title').on('keyup', backSpaceDisabledDraftButtonByTitle);
      $('.editor-title').on('focus', disabledDraftButtonByTitle);

      if (editor_title) {
        document.getElementsByClassName('editor-title')[0].value = editor_title;
      }

      if (content) {
        returnEditor.setHTML(content);
      }

      if ($('.editor-title[placeholder="请输入问题标题"]').length > 0 && window.localStorage.getItem(`${state.auto_save_key}-title`)) {
        return;
      }

      if (window.localStorage.getItem(`${state.auto_save_key}-content`) !== '<p><br></p>' && window.localStorage.getItem(`${state.auto_save_key}-content`)) {
        return;
      }

      $('.mdui_editor-toolbar-menu-clear_drafts').addClass('disabled-draft');
    },
    ondestroy: () => {
      $('.editor-title').off('input', activeDraftButtonByTitle);
      $('.editor-content').off('input', activeDraftButtonByContent);
      $('.editor-title').off('keyup', backSpaceDisabledDraftButtonByTitle);
      $('.editor-title').off('keydown', backSpaceDisabledDraftButtonByTitle);
      $('.editor-content').off('keyup', backSpaceDisabledDraftButtonByContent);
      $('.editor-content').off('keydown', backSpaceDisabledDraftButtonByContent);
    }
  }, h(Header, {
    isUp: state.editor_index_minimize
  }), h("div", {
    class: cc([bodyClassName, 'body'])
  }, withTitle ? h(Title, null) : null, withTopics ? h(Topics, null) : null, isComment ? h("textarea", {
    class: "mdui-textfield-input comment-textarea comment_textarea",
    placeholder: "\u5199\u4E0B\u4F60\u7684\u8BC4\u8BBA",
    disabled: publishing,
    oncreate: element => {
      $(element).val(content);
    }
  }) : null, h("div", {
    className: `editor-content mdui-text-color-theme-text ${isComment ? 'mdui-hidden' : ''}`
  }), h("div", {
    className: `editor-toolbar`
  }, h(SubmitButton, null))));
});