import { h } from 'hyperapp';
import Item from './sub/item/view.jsx';
import './index.less';
export default (({
  font_reason_id,
  img_reason_id,
  other_reason_id,
  font_selections,
  img_selections,
  other_selections,
  onChangeOtherSelector,
  onChangeFontSelector,
  onChangeImgSelector
}) => h("div", {
  class: "fail-reason-container"
}, h("div", {
  class: "fail-reason-label-container"
}, h("span", {
  class: "fail-reason-label"
}, "\u4E0D\u901A\u8FC7\u539F\u56E0"), h("span", {
  class: "fail-reason-label-tips"
}, "\uFF08\u53EF\u5355\u9009/\u591A\u9009\uFF09")), h(Item, {
  label: "\u6587\u5B57\u539F\u56E0",
  selections: font_selections,
  activeId: font_reason_id,
  onChange: onChangeFontSelector,
  type: "font"
}), h(Item, {
  label: "\u56FE\u7247\u539F\u56E0",
  selections: img_selections,
  activeId: img_reason_id,
  type: "image",
  onChange: onChangeImgSelector
}), h(Item, {
  label: "\u5176\u4ED6\u539F\u56E0",
  selections: other_selections,
  activeId: other_reason_id,
  type: "other",
  onChange: onChangeOtherSelector
})));