import mdui from 'mdui';
import extend from 'mdui.jq/es/functions/extend';
import { get as getQuestion } from 'mdclub-sdk-js/es/QuestionApi';
import { get as getAnswer } from 'mdclub-sdk-js/es/AnswerApi';
import { getCommentDetail } from '~/apis/comments';
/**
 * @typedef {Stores.PublishPageState} State
 * @typedef {Stores.PublishPageActions} Actions
 */

let dialog;
const as = {
  detailDialogCreate: ({
    element
  }) => {
    dialog = new mdui.Dialog(element, {
      history: false
    });
  },

  /**
   * 打开对话框
   * @param question
   * 该参数为整型时，表示为提问ID，需要根据该ID获取提问信息；
   * 该参数为对象时，表示为完整的提问信息，不需要再请求数据
   */
  detailDialogOpen: ({
    type,
    id
  }) => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    let task;

    const processQuestion = questionId => getQuestion({
      question_id: questionId,
      include: ['user', 'topics', 'is_following', 'voting']
    }).then(({
      data
    }) => {
      const relationUser = data.relationships ? data.relationships.user : {};
      actions.setState({
        detailDialog: {
          title: data.title,
          username: relationUser.username || '',
          userAvatar: relationUser.avatar || null,
          datetime: data.update_time,
          content: data.content_rendered,
          content_markdown: data.content_markdown,
          isLoaded: true,
          isLoading: false
        }
      });
    });

    const processAnswer = answerId => getAnswer({
      answer_id: answerId,
      include: ['user', 'voting', 'question']
    }).then(({
      data
    }) => {
      const relationUser = data.relationships ? data.relationships.user : {};
      actions.setState({
        detailDialog: {
          title: '',
          username: relationUser.username || '',
          userAvatar: relationUser.avatar || null,
          datetime: data.update_time,
          content: data.content_rendered,
          content_markdown: data.content_markdown,
          isLoaded: true,
          isLoading: false
        }
      });
    });

    if (type === 'question') {
      task = () => processQuestion(id);
    } else if (type === 'answer') {
      task = () => processAnswer(id);
    } else if (type === 'comment') {
      task = () => getCommentDetail(id).then(detail => {
        if (detail.commentable_type === 'answer') {
          return processAnswer(detail.commentable_id);
        }

        if (detail.commentable_type === 'question') {
          return processQuestion(detail.commentable_id);
        } // TODO: processArticle


        return Promise.reject(new Error('详情不存在'));
      });
    } else {
      task = () => Promise.reject(new Error('详情不存在'));
    }

    actions.setState({
      detailDialog: extend({}, state.detailDialog, {
        isLoading: true,
        isLoaded: false
      })
    });
    dialog.open();
    return task();
  },

  /**
   * 关闭对话框
   */
  detailDialogClose: () => {
    if (!dialog) {
      return;
    }

    dialog.close();
  },

  detailDialogUpdate() {
    dialog.handleUpdate();
  }

};
export default as;