import extend from 'mdui.jq/es/functions/extend';
import { $window } from 'mdui/es/utils/dom';
import commonActions from '~/utils/actionsAbstract';
const as = {
  onCreate: user => (state, actions) => {
    $window.on('scroll', actions.scrollToCloseMenu);
  },
  onDetroy: () => (state, actions) => {
    $window.off('scroll', actions.scrollToCloseMenu);
  },
  // 滚动关闭菜单
  scrollToCloseMenu: () => (_, actions) => {
    actions.setState({
      isClick: false
    });
  }
};
export default extend(as, commonActions);