import { getRequest, postRequest } from 'mdclub-sdk-js/es/util/requestAlias';
import { buildURL } from 'mdclub-sdk-js/es/util/requestHandler';
/**
 *
 * @param {number} commentId
 * @returns {Promise<Apis.ResCommentDetail>}
 */

export function getCommentDetail(commentId) {
  return getRequest(buildURL(`/comments/{comment_id}/detail`, {
    comment_id: commentId
  })).then(res => {
    if (res.code !== 0) {
      return Promise.reject(new Error('服务器异常'));
    }

    return res.data;
  });
} // 创建评论

export function createComment(params) {
  return postRequest(buildURL('/comments'), params, ['question_id', 'answer_id', 'quote_id', 'content']).then(res => {
    if (res.code !== 0) {
      return Promise.reject(new Error('服务器异常'));
    }
  });
}