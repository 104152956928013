import { h } from 'hyperapp';
import cc from 'classcat';
import $ from 'mdui.jq';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Pagination from '~/components/pagination/view.jsx';

const CheckAll = ({
  isChecked,
  onChange
}) => h("th", {
  class: "mdui-table-cell-checkbox"
}, h("label", {
  class: "mdui-checkbox"
}, h("input", {
  type: "checkbox",
  checked: isChecked,
  onchange: onChange
}), h("i", {
  class: "mdui-checkbox-icon"
})));

const CheckOne = ({
  isChecked,
  onChange,
  avatar = false
}) => h("td", {
  class: cc(['mdui-table-cell-checkbox', {
    'with-avatar': avatar
  }])
}, avatar ? h("img", {
  class: "avatar",
  src: avatar
}) : null, h("label", {
  class: "mdui-checkbox",
  onclick: e => e.stopPropagation()
}, h("input", {
  type: "checkbox",
  checked: isChecked,
  onchange: onChange
}), h("i", {
  class: "mdui-checkbox-icon"
})));

export const ActionBtn = ({
  icon,
  label,
  href,
  onClick,
  key
}) => h("a", {
  class: "mdui-btn mdui-btn-icon mdui-text-color-theme-icon",
  "mdui-tooltip": `{content: '${label}', delay: 300}`,
  href: href,
  target: href ? '_blank' : false,
  onclick: onClick,
  key: key
}, h("i", {
  class: "mdui-icon material-icons"
}, icon));

const ColumnTH = ({
  column
}) => h("th", {
  key: column.field,
  className: column.className,
  style: column.width && `width: ${column.width}px;`
}, column.title);

const ColumnTHChecked = ({
  batchButtons,
  colspan,
  checkedCount
}) => h("th", {
  colspan: colspan
}, h("span", null, batchButtons.map(button => h(ActionBtn, {
  label: button.label,
  icon: button.icon,
  onClick: () => {
    button.onClick(button);
  }
}))), h("span", {
  class: "mdui-float-right"
}, "\u5DF2\u9009\u62E9 ", checkedCount, " \u4E2A\u9879\u76EE"));
/**
 *
 * @param {{column: Components.Table1ComponentProps['columns'][number], fieldData: Components.Table1DatasourceItem['field'][string], row: Components.Table1DatasourceItem}} param0
 * @returns
 */


const ColumnTD = ({
  column,
  fieldData,
  row
}) => {
  const style = column.width && `width: ${column.width}px;`;
  return h("td", {
    className: fieldData.className || '',
    style: style
  }, fieldData.render ? fieldData.render(fieldData, row) : fieldData.value);
};
/**
 *
 * @param {Components.Table1ComponentProps} param0
 */


export default (({
  className = '',
  loading,
  columns,
  datasource,
  checkOptions,
  pagination
}) => {
  const isEmpty = !loading && !datasource.length;
  const isLoading = loading;
  const rowCheckedStatus = checkOptions ? checkOptions.rowCheckedStatus : {};
  const checkedRowIds = checkOptions ? Object.keys(rowCheckedStatus).reduce((acc, curr) => {
    if (checkOptions.rowCheckedStatus[curr]) {
      acc.push(curr);
    }

    return acc;
  }, []) : [];
  const hasCheckedRow = checkedRowIds.length > 0;
  const isCheckedAll = checkedRowIds.length === datasource.length;

  const onCreate = element => {
    $(element).find('tbody').on('scroll', e => {
      const isScrollTop = !!e.target.scrollTop;
      const $target = $(element);

      if (isScrollTop) {
        $target.addClass('is-top');
      } else {
        $target.removeClass('is-top');
      }
    });
  };

  const onDestroy = element => {
    $(element).find('tbody').off('scroll');
  };

  return h("div", {
    class: cc(['mc-table-1', className]),
    oncreate: onCreate,
    ondestroy: onDestroy
  }, h("table", {
    class: "mdui-table"
  }, h("thead", null, h("tr", {
    class: cc([{
      checked: hasCheckedRow
    }])
  }, checkOptions ? h(CheckAll, {
    isChecked: isCheckedAll,
    onChange: () => checkOptions.onAllCheck(!isCheckedAll)
  }) : null, hasCheckedRow ? h(ColumnTHChecked, {
    colspan: columns.length,
    batchButtons: checkOptions.batchButtons,
    checkedCount: checkedRowIds.length
  }) : null, !hasCheckedRow ? [columns.map(column => h(ColumnTH, {
    column: column
  })), pagination ? h("th", {
    class: "actions",
    key: "1"
  }, h(Pagination, pagination.component)) : null] : null)), h(Loading, {
    show: isLoading
  }), h(Empty, {
    show: isEmpty
  }), h("tbody", {
    class: cc([{
      'is-loading': isLoading,
      'is-empty': isEmpty
    }])
  }, datasource.map(row => h("tr", {
    key: row.rowId,
    "data-id": row.rowId,
    class: cc([{
      'mdui-table-row-selected': rowCheckedStatus[row.rowId] // 'last-visit': state.lastVisitId === row[state.primaryKey],

    }]),
    onclick: () => {
      if (typeof row.onRowClick === 'function' // e.target.nodeName === 'TD'
      ) {
        row.onRowClick(row);
      }
    }
  }, checkOptions ? h(CheckOne, {
    isChecked: rowCheckedStatus[row.rowId],
    onChange: () => checkOptions.onRowCheck(!rowCheckedStatus[row.rowId], row),
    avatar: row.avatar ? row.avatar : false
  }) : null, columns.map(column => h(ColumnTD, {
    column: column,
    fieldData: row.field[column.field],
    row: row
  })), pagination ? h("td", {
    class: "actions"
  }, pagination.renderTDContent(row)) : null)))));
});