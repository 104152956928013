import { h } from 'hyperapp';
import { location } from 'hyperapp-router';
import mdui from 'mdui';
import './index.less';
import extend from 'mdui.jq/es/functions/extend';
import { timeFormat } from '~/utils/time';
import { fullPath } from '~/utils/path';
import { emit } from '~/utils/pubsub';
import Empty from '~/components/empty/view.jsx';
import Loading from '~/components/loading/view.jsx';
import Tab from '~/components/tab/view.jsx';
import { ContentListItem } from '~/components/list-item/view.jsx';
import MenuSelection from '~/components/menu-selection/view.jsx';
import { default as ReviewTable, ActionBtn } from '~/components/table-1/view.jsx';
import TagStatus from '~/components/tag-status/view.jsx';
import Editor from '~/components/editor/view.jsx';
import Pagination from '~/components/pagination/view.jsx';
/** @typedef {{state: Stores.PublishPageState, actions: Stores.PublishPageActions}} RenderParam */

export default ((
/** @type {RenderParam} */
{
  state,
  actions
}) => {
  /**
   * @param {Stores.PublishPageState['reviews_data'][number]} item
   */
  const concatDetailFullUrl = item => {
    if (item.type === 'question') {
      return fullPath(`/review-detail?type=question&id=${item.id}&state=${item.status}`);
    }

    if (item.type === 'answer') {
      return fullPath(`/review-detail?type=answer&id=${item.id}&state=${item.status}&question_id=${item.questionId}`);
    }

    if (item.type === 'comment') {
      return fullPath(`/review-detail?type=comment&id=${item.id}&state=${item.status}&question_id=${item.commentModalOptions.type === 'question' ? item.commentModalOptions.id : ''}`);
    }

    return fullPath('/');
  };

  const reviewTableColumns = [{
    field: 'reviewType',
    title: '类型',
    className: 'review-type-th'
  }, {
    field: 'reviewDate',
    title: '日期',
    className: 'review-date-th'
  }];
  /** @type {Components.Table1DatasourceItem[]} */

  const reviewTableData = state.reviews_data.map(item => {
    return {
      rowId: `${item.type}_${item.id}`,
      onRowClick: () => {
        const newerData = state.reviews_newerIds.find(newer => newer.id === item.id && newer.type === item.type);
        location.actions.go(concatDetailFullUrl(item));

        if (newerData) {
          window.app.notifications.readOne(newerData.nid);
        }
      },
      field: {
        reviewType: {
          render() {
            const typeFontMap = {
              question: '问',
              answer: '答',
              comment: '评'
            };
            return h("div", {
              className: "review-type-wrapper"
            }, !!state.reviews_newerIds.find(newer => newer.id === item.id && newer.type === item.type) && item.status === 2 ? h("i", {
              className: "review-dot"
            }) : null, h("span", {
              className: "review-type-tag"
            }, typeFontMap[item.type]), item.title);
          },

          className: 'review-type-td'
        },
        reviewDate: {
          value: timeFormat(item.updateTime, 'YYYY年MM月DD日'),
          className: 'review-date-td'
        }
      },
      extraData: extend({}, item)
    };
  });

  const renderEmptyText = tabName => {
    if (tabName === 'questions') {
      return '该用户没有发表提问';
    }

    if (tabName === 'comments') {
      return '该用户没有发表评论';
    }

    if (tabName === 'answers') {
      return '该用户没有发表回答';
    }

    if (tabName === 'reviews') {
      return '该用户没有审核数据';
    }

    if (tabName === 'followings') {
      return '该用户没有关注问题';
    }

    return '没有数据';
  };

  const handleReviewUpdateSelectionChange = ({
    id
  }) => {
    actions.setState({
      reviews_order: id,
      reviews_data: []
    });
    actions.loadReviews(1);
  };

  const handleReviewStatusChange = ({
    id: status
  }) => {
    actions.setState({
      reviewsFilterOptions: extend(state.reviewsFilterOptions, {
        status: Number(status)
      }),
      reviews_data: []
    });
    actions.loadReviews(1);
  };

  const handleReviewTypeChange = ({
    id: type
  }) => {
    actions.setState({
      reviewsFilterOptions: extend(state.reviewsFilterOptions, {
        type
      }),
      reviews_data: []
    });
    actions.loadReviews(1);
  };

  const handleReviewPageChange = page => {
    actions.loadReviews(page);
  };

  const handleReviewCheckAll = checked => {
    actions.setState({
      reviews_data: state.reviews_data.map(item => {
        if (item.status !== 2) {
          return item;
        }

        return extend(item, {
          checked
        });
      })
    });
  };

  const handleReviewCheckOne = (checked, reviewId) => {
    actions.setState({
      reviews_data: state.reviews_data.map(item => {
        if (item.id === reviewId && item.status === 2) {
          return extend(item, {
            checked
          });
        }

        return item;
      })
    });
  };

  const handleReviewEdit = ({
    title,
    content
  }) => {
    actions.editReviewBy({
      type: state.editingReview.type,
      id: state.editingReview.id,
      title,
      content
    }).then(() => {
      mdui.snackbar('更新成功');
      actions.editorClose();
      actions.setState({
        editingReview: undefined // 关闭时销毁Editor组件, 原因看渲染的注释

      });
    }).catch(err => {
      mdui.snackbar(err.message);
    });
  };

  const handleReviewDelete = ({
    type,
    id,
    title
  }) => {
    const specTitle = `“${title.length < 12 ? title : `${title.slice(0, 10)}...${title.slice(-2)}`}”`;
    const typeList = {
      answer: '回答',
      question: '问题',
      comment: '评论'
    };
    emit('pop_open', {
      title: '是否删除？',
      contentHTML: `是否删除${specTitle}${typeList[type]}？`,
      type: 'warn',
      cancelText: '取消',

      onOk($pop, $props) {
        $props.set({
          isLoading: true,
          okText: '正在删除'
        });
        actions.deleteReviewBy({
          type,
          id
        }).then(() => {
          $pop.close();
        }).catch(err => {
          mdui.snackbar(err.message);
        }).finally(() => {
          $props.set({
            isLoading: false,
            okText: '确定'
          });
        });
      }

    });
  };

  const getEditorTitle = () => {
    if (!state.editingReview) {
      return '';
    }

    const {
      type
    } = state.editingReview;

    if (type === 'question') {
      return '编辑提问';
    }

    if (type === 'answer') {
      return '编辑回答';
    }

    if (type === 'comment') {
      return '编辑评论';
    }

    return '';
  };

  const getCommentLinkUrl = comment => {
    if (comment.answer_id) {
      return `/questions/${comment.question_id}/answers/${comment.answer_id}`;
    }

    return `/questions/${comment.question_id}`;
  };

  const clickDeleteAllSelected = () => {
    const selectedData = state.reviews_data.filter(item => item.checked);
    emit('pop_open', {
      title: '是否删除？',
      contentHTML: `是否删除${selectedData.length}个项目？`,
      type: 'warn',
      cancelText: '取消',

      onOk($pop, $props) {
        $props.set({
          isLoading: true,
          okText: '正在删除'
        });
        actions.deleteSelected().then(() => {
          $pop.close();
          actions.loadReviews(1);
        }).catch(err => {
          mdui.snackbar(err.message);
        }).finally(() => {
          $props.set({
            isLoading: false,
            okText: '确定'
          });
        });
      }

    });
  };

  const clickShowRelativeDetailDialog = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    actions.detailDialogOpen({
      type: item.commentModalOptions.type,
      id: item.commentModalOptions.id
    }).catch(err => {
      mdui.snackbar(err.message);
    });
  };

  return h("div", {
    class: "contexts mdui-card review-contexts"
  }, h(Tab, {
    items: [{
      name: '提问',
      hash: 'questions',
      count: state.questions_pagination ? state.questions_pagination.total : 0,
      dot: state.questions_newerIds.length > 0
    }, {
      name: '回答',
      hash: 'answers',
      count: state.answers_pagination ? state.answers_pagination.total : 0,
      dot: state.answers_newerIds.length > 0
    }, {
      name: '评论',
      hash: 'comments',
      count: state.comments_pagination ? state.comments_pagination.total : 0,
      dot: state.comments_newerIds.length > 0
    }, {
      name: '审核',
      hash: 'reviews',
      count: state.reviews_pagination ? state.reviews_pagination.total : 0,
      dot: state.reviews_newerIds.length > 0
    }, {
      name: '已关注问题',
      hash: 'followings',
      count: state.followings_pagination ? state.followings_pagination.total : 0
    }]
  }), state.tabs.map(tabName => {
    const pagination = state[`${tabName}_pagination`];
    const data = state[`${tabName}_data`];
    const order = state[`${tabName}_order`];
    const isLoading = state[`${tabName}_loading`];
    const isEmpty = !isLoading && !data.length && pagination;
    return () => h("div", {
      id: tabName
    }, tabName === 'questions' ? h("div", {
      className: "filter-header g-flex g-justify-content-between g-align-items-center"
    }, h(MenuSelection, {
      selections: [{
        id: '-update_time',
        text: '更新时间(从晚到早)'
      }, {
        id: 'update_time',
        text: '更新时间(从早到晚)'
      }],
      activeId: order || '-update_time',
      key: "question-update-selection",
      disabled: isLoading,
      onChange: ({
        id: order
      }) => actions.changeOrder(order)
    }), pagination ? h(Pagination, {
      currentPage: pagination.page,
      perPage: pagination.per_page,
      totalPage: pagination.pages,
      total: pagination.total,
      loading: isLoading,
      onPageChange: page => {
        actions.loadPageData({
          tabName: 'questions',
          page
        });
      }
    }) : null) : null, tabName === 'comments' ? h("div", {
      className: "filter-header g-flex g-justify-content-between g-align-items-center"
    }, h(MenuSelection, {
      selections: [{
        id: '-update_time',
        text: '更新时间(从晚到早)'
      }, {
        id: 'update_time',
        text: '更新时间(从早到晚)'
      }, {
        id: '-vote_count',
        text: '最热门'
      }],
      activeId: order || '-update_time',
      key: "comments-update-selection",
      onChange: ({
        id: order
      }) => actions.changeOrder(order)
    }), pagination ? h(Pagination, {
      currentPage: pagination.page,
      perPage: pagination.per_page,
      totalPage: pagination.pages,
      total: pagination.total,
      loading: isLoading,
      onPageChange: page => {
        actions.loadPageData({
          tabName: 'comments',
          page
        });
      }
    }) : null) : null, tabName === 'answers' ? h("div", {
      className: "filter-header g-flex g-justify-content-between g-align-items-center"
    }, h(MenuSelection, {
      selections: [{
        id: '-update_time',
        text: '更新时间(从晚到早)'
      }, {
        id: 'update_time',
        text: '更新时间(从早到晚)'
      }, {
        id: '-vote_count',
        text: '最热门'
      }],
      activeId: order || '-update_time',
      key: "answers-update-selection",
      onChange: ({
        id: order
      }) => actions.changeOrder(order)
    }), pagination ? h(Pagination, {
      currentPage: pagination.page,
      perPage: pagination.per_page,
      totalPage: pagination.pages,
      total: pagination.total,
      loading: isLoading,
      onPageChange: page => {
        actions.loadPageData({
          tabName: 'answers',
          page
        });
      }
    }) : null) : null, tabName === 'reviews' ? h("div", {
      className: "filter-header g-flex g-justify-content-between g-align-items-center"
    }, h("div", {
      className: "g-flex"
    }, h(MenuSelection, {
      selections: [{
        id: '-update_time',
        text: '更新时间(从晚到早)'
      }, {
        id: 'update_time',
        text: '更新时间(从早到晚)'
      }],
      activeId: order,
      key: "review-update-selection",
      disabled: isLoading,
      onChange: handleReviewUpdateSelectionChange
    })), h("div", {
      className: "g-flex"
    }, h(MenuSelection, {
      className: "review-status-selection",
      selections: [{
        id: '-1',
        text: '全部审核'
      }, {
        id: '0',
        text: '审核中'
      }, {
        id: '2',
        text: '审核失败'
      }],
      activeId: typeof state.reviewsFilterOptions.status === 'number' ? String(state.reviewsFilterOptions.status) : '-1',
      key: "review-status-selection",
      disabled: isLoading,
      onChange: handleReviewStatusChange
    }), h(MenuSelection, {
      selections: [{
        id: 'all',
        text: '全部类型'
      }, {
        id: 'question',
        text: '提问'
      }, {
        id: 'answer',
        text: '回答'
      }, {
        id: 'comment',
        text: '评论'
      }],
      activeId: state.reviewsFilterOptions.type || 'all',
      key: "review-type-selection",
      disabled: isLoading,
      onChange: handleReviewTypeChange
    }))) : null, tabName === 'followings' ? h("div", {
      className: "filter-header g-flex g-justify-content-end g-align-items-center"
    }, pagination ? h(Pagination, {
      currentPage: pagination.page,
      perPage: pagination.per_page,
      totalPage: pagination.pages,
      total: pagination.total,
      loading: isLoading,
      onPageChange: page => {
        actions.loadPageData({
          tabName: 'followings',
          page
        });
      }
    }) : null) : null, h(Loading, {
      show: isLoading
    }), data.length ? h("div", {
      class: "item-list"
    }, tabName === 'questions' ? data.map(question => {
      const item = {
        id: question.question_id,
        title: question.title,
        description: question.content_rendered,
        updateTime: question.answer_time || question.update_time,
        updateTimeDesc: question.answer_time ? '回复于' : '更新于',
        replys: `${question.answer_count} 个回答`
      };
      return h(ContentListItem, {
        item: item,
        type: "question",
        path: `/questions/${question.question_id}`,
        last_visit_id: state.last_visit_question_id,
        onClick: () => actions.afterItemClick(question),
        dot: state.questions_newerIds.find(el => el.id === item.id)
      });
    }) : null, tabName === 'comments' ? data.map(comment => {
      const relativeDetail = comment.relationships ? comment.relationships.detail : {};
      const item = {
        id: comment.comment_id,
        title: comment.content,
        description: comment.content,
        updateTime: comment.update_time,
        updateTimeDesc: '更新于',
        replys: ''
      };
      return h(ContentListItem, {
        item: item,
        type: "comment",
        path: getCommentLinkUrl(comment),
        last_visit_id: state.last_visit_article_id,
        onClick: () => {
          actions.afterItemClick(comment);
        },
        dot: state.comments_newerIds.find(el => el.id === item.id)
      });
    }) : null, tabName === 'answers' ? data.map(answer => {
      const item = {
        id: answer.answer_id,
        title: answer.relationships.question.title,
        description: answer.content_rendered,
        updateTime: answer.update_time,
        updateTimeDesc: '更新于',
        replys: `${answer.comment_count} 条评论`
      };
      return h(ContentListItem, {
        item: item,
        type: "answer",
        path: `/questions/${answer.question_id}/answers/${answer.answer_id}`,
        last_visit_id: state.last_visit_question_id,
        onClick: () => actions.afterItemClick(answer),
        dot: state.answers_newerIds.find(el => el.id === item.id)
      });
    }) : null, tabName === 'reviews' ? h(ReviewTable, {
      className: `review-table ${state.reviewsFilterOptions.status !== 2 ? 'no-checkbox' : ''}`,
      columns: reviewTableColumns,
      datasource: reviewTableData,
      pagination: state.reviews_pagination ? {
        component: {
          currentPage: state.reviews_pagination.page,
          perPage: state.reviews_pagination.per_page,
          totalPage: state.reviews_pagination.pages,
          total: state.reviews_pagination.total,
          loading: isLoading,
          onPageChange: handleReviewPageChange
        },

        renderTDContent(row) {
          const {
            extraData
          } = row;
          return h("div", null, extraData.status === 0 ? [h(TagStatus, {
            text: "\u5BA1\u6838\u4E2D",
            status: "warning",
            key: "0"
          }), h("div", {
            key: "1"
          }, h(ActionBtn, {
            label: "\u65B0\u7A97\u53E3\u6253\u5F00",
            icon: "open_in_new",
            key: "open_in_new",
            href: concatDetailFullUrl(extraData),
            onClick: e => {
              e.stopPropagation();
            }
          }), extraData.type !== 'question' ? h(ActionBtn, {
            label: "\u67E5\u770B\u6240\u5C5E\u5185\u5BB9",
            icon: "question_answer",
            key: "question_answer",
            href: "",
            onClick: e => clickShowRelativeDetailDialog(e, extraData)
          }) : null, h(ActionBtn, {
            label: "\u5220\u9664",
            icon: "delete",
            key: "delete",
            onClick: e => {
              e.stopPropagation();
              handleReviewDelete(extraData);
            }
          }))] : null, extraData.status === 2 ? [h(TagStatus, {
            text: "\u5BA1\u6838\u4E0D\u901A\u8FC7",
            status: "fail",
            key: "0"
          }), h("div", {
            key: "1"
          }, h(ActionBtn, {
            label: "\u65B0\u7A97\u53E3\u6253\u5F00",
            icon: "open_in_new",
            key: "open_in_new",
            href: concatDetailFullUrl(extraData),
            onClick: e => {
              e.stopPropagation();
            }
          }), extraData.type !== 'question' ? h(ActionBtn, {
            label: "\u67E5\u770B\u6240\u5C5E\u5185\u5BB9",
            icon: "question_answer",
            key: "question_answer",
            href: "",
            onClick: e => clickShowRelativeDetailDialog(e, extraData)
          }) : null, extraData.editableForFail === 1 ? h(ActionBtn, {
            label: "\u7F16\u8F91",
            icon: "edit",
            key: "edit",
            href: "",
            onClick: e => {
              e.preventDefault();
              e.stopPropagation();
              actions.setState({
                editingReview: extraData
              });
              actions.editorOpen();
            }
          }) : null, h(ActionBtn, {
            label: "\u5220\u9664",
            icon: "delete",
            key: "delete",
            onClick: e => {
              e.stopPropagation();
              handleReviewDelete(extraData);
            }
          }))] : null);
        }

      } : undefined,
      checkOptions: state.reviewsFilterOptions.status === 2 ? {
        rowCheckedStatus: state.reviews_data.reduce((acc, curr) => {
          acc[`${curr.type}_${curr.id}`] = curr.checked;
          return acc;
        }, {}),
        batchButtons: [{
          label: '批量删除',
          icon: 'delete',
          onClick: clickDeleteAllSelected
        }],
        onAllCheck: handleReviewCheckAll,

        onRowCheck(checked, row) {
          handleReviewCheckOne(checked, row.extraData.id);
        }

      } : undefined
    }) : null, tabName === 'followings' ? data.map(question => {
      const item = {
        id: question.question_id,
        title: question.title,
        description: question.content_rendered,
        updateTime: question.answer_time || question.update_time,
        updateTimeDesc: question.answer_time ? '回复于' : '更新于',
        replys: `${question.answer_count} 个回答`
      };
      return h(ContentListItem, {
        item: item,
        path: `/questions/${question.question_id}`,
        last_visit_id: state.last_visit_question_id,
        onClick: () => actions.afterItemClick(question)
      });
    }) : null) : null, h(Empty, {
      show: isEmpty,
      title: renderEmptyText(tabName)
    }), tabName === 'reviews' && state.editingReview ? h(Editor, {
      id: "review-editor",
      title: getEditorTitle(),
      withTitle: state.editingReview.type === 'question',
      withTopics: false,
      onSubmit: handleReviewEdit,
      publishing: state.isPublishEditingReview,
      isComment: state.editingReview.type === 'comment',
      content: state.editingReview ? state.editingReview.content : '',
      editor_title: state.editingReview ? state.editingReview.title : '',
      state: state,
      actions: actions,
      questionTitle: state.editingReview.type === 'question' ? state.editingReview.title : ''
    }) : null);
  }));
});