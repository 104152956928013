import { h } from 'hyperapp';
import ButtonBase from '../button-base/view.jsx';
import Menu from '~/common/menu/view.jsx';
import { forward } from '~/utils/forward.js';
import { requireStatic } from '~/utils/requireStatic';
import { returnUrl } from '~/utils/forward';
import Avatar from '~/components/avatar/view.jsx';
import './index.less';
export default (({
  user,
  state,
  actions
}) => {
  let {
    isClick
  } = state;
  let isLogin = false;
  let avatar;
  let nickname;
  let username;
  let avatarMenu = []; // TODO: 先看看登录后会不会有影响，后面处理

  const avatarActive = false;
  const navigation = [{
    text: '产品介绍',
    to: '/introduction'
  }, {
    text: '关于我们',
    to: '/about'
  }];
  const host = window.location.host.indexOf('test') > 0 ? 'test2.xk.design' : 'xk.design';

  const forwardLogin = () => {
    // 保留当前的路径 方便登录后返回当前页面 //test2.xk.design/login
    // 判断当前环境
    forward(`//${host}/login?f=bbs&redirect=${encodeURI(window.location.href)}`);
  };

  const forwardWork = () => {
    window.location.href = `//${host}/files/recent`;
  };

  if (user) {
    const name = user.nickname || user.username;
    avatarMenu = [{
      text: name,
      id: 'username'
    }, {
      text: '退出',
      id: 'label'
    }];
    isLogin = true;
    avatar = user.avatar;
    nickname = user.nickname;
    username = user.username;
  }

  return h("div", {
    class: "website-header-container",
    oncreate: () => actions.onCreate(user)
  }, h("div", {
    class: "website-center"
  }, h("div", {
    class: "website-menu-icon-group"
  }, isClick ? h("i", {
    class: "menu-icon mdui-icon material-icons",
    onclick: () => {
      actions.setState({
        isClick: false
      });
    }
  }, "close") : null, !isClick ? h("i", {
    class: "menu-close-icon mdui-icon material-icons",
    onclick: () => {
      actions.setState({
        isClick: true
      });
    }
  }, "menu") : null), h("a", {
    href: returnUrl('/')
  }, h("img", {
    class: "icon",
    src: requireStatic(require('../../svg/logo.svg')),
    onclick: () => {
      isClick = false;
    }
  }), h("img", {
    src: requireStatic(require('../../svg/logo2.svg')),
    class: "icon-simple"
  })), h("ul", {
    class: "website-navigation"
  }, navigation.map((item, index) => {
    return h("a", {
      key: index,
      href: returnUrl(item.to),
      "active-class": "li-active"
    }, item.text);
  })), !isLogin ? h("div", {
    class: "button-group"
  }, h(ButtonBase, {
    className: "dashed",
    width: 80,
    onClick: forwardLogin
  }, "\u767B\u5F55"), h(ButtonBase, {
    className: "website-register",
    type: "info",
    onClick: forwardLogin
  }, "\u514D\u8D39\u6CE8\u518C")) : null, isLogin ? h("div", {
    class: "button-group"
  }, h("div", {
    class: "avatar"
  }, h(Avatar, {
    imgClassName: avatarActive ? 'active' : '',
    isShowName: false,
    avatar: avatar,
    nickname: nickname,
    username: username
  }), h(Menu, {
    class: "avatar-menu",
    menuDate: avatarMenu
  })), h(ButtonBase, {
    className: "button-group-primary",
    type: "primary",
    onClick: forwardWork
  }, "\u524D\u5F80\u5DE5\u4F5C\u53F0")) : null), isClick ? h("ul", {
    class: "website-navigation-active"
  }, navigation.map((item, index) => {
    return h("a", {
      key: index,
      href: returnUrl(item.to)
    }, item.text);
  })) : null);
});