import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import $ from 'mdui.jq'; // import { unescape } from 'html-escaper';

import { fullPath } from '~/utils/path'; // import { emit } from '~/utils/pubsub';
// import currentUser from '~/utils/currentUser';

import './index.less';
import Avatar from '~/components/avatar/view.jsx'; // import Follow from '~/components/follow/view.jsx';
// import OptionsButton from '~/components/options-button/view.jsx';

const StatItem = ({
  url,
  label,
  count
}) => h(Link, {
  to: url,
  class: "mdui-btn mdui-ripple"
}, h("label", {
  class: "mdui-text-color-theme-secondary"
}, label), h("span", {
  class: "mdui-text-color-theme-text"
}, count));

export default (({
  actions,
  user,
  dataName,
  primaryKey = null,
  primaryValue = null
}, children) => {
  let avatar;
  let nickname;
  let username;

  if (user) {
    avatar = user.avatar;
    nickname = user.nickname;
    username = user.username;
  }

  return h("div", {
    class: "mc-user-popover",
    key: "mc-user-popover",
    onclick: event => event.preventDefault(),
    oncreate: element => {
      actions.onUserPopoverCreate({
        element,
        dataName,
        primaryKey,
        primaryValue
      });
    }
  }, children, h("div", {
    class: "popover mdui-menu",
    key: "popover"
  }, user.user_id ? [h("div", {
    class: "mc-user-line",
    key: "0"
  }, h(Link, {
    key: "avatar",
    class: "avatar user-popover-trigger",
    to: fullPath(`/users/${user.user_id}`)
  }, h(Avatar, {
    avatar: avatar,
    nickname: nickname,
    username: username,
    userNameClassName: "username"
  }))), h("div", {
    class: "stats",
    key: "1"
  }, h(StatItem, {
    url: fullPath(`/users/${user.user_id}#answers`),
    label: "\u56DE\u7B54",
    count: user.answer_count
  }), h(StatItem, {
    url: fullPath(`/users/${user.user_id}#comments`),
    label: "\u8BC4\u8BBA",
    count: user.article_count
  }))] : null, !user.user_id ? h("div", {
    key: "mc-user-popover-spinner",
    class: "mdui-spinner",
    oncreate: element => $(element).mutation()
  }) : null));
});