import mdui from 'mdui';
import extend from 'mdui.jq/es/functions/extend';
import { getQuestions, getAnswers, getComments, getMyFollowingQuestions } from 'mdclub-sdk-js/es/UserApi';
import { update as updateQuestion, del as deleteQuestion } from 'mdclub-sdk-js/es/QuestionApi';
import { update as updateAnswer, del as deleteAnswer } from 'mdclub-sdk-js/es/AnswerApi';
import { update as updateComment, del as deleteComment } from 'mdclub-sdk-js/es/CommentApi';
import commonActions from '~/utils/actionsAbstract';
import userPopoverActions from '~/components/user-popover/actions';
import { emit } from '~/utils/pubsub';
import apiCatch from '~/utils/errorHandler';
import { tabs } from './state';
import { getReviewList } from '~/apis/reviews';
import editorActions from '~/components/editor/actions';
import detailDialogActions from './components/detail-dialog/actions';
/**
 * @typedef {Stores.PublishPageState} State
 * @typedef {Stores.PublishPageActions} Actions
 */

const TABNAME_QUESTIONS = 'questions';
const TABNAME_COMMENTS = 'comments'; // const TABNAME_ANSWERS = 'answers';

const TABNAME_FOLLOWINGS = 'followings';
let tab;
let tabIndex = tabs.indexOf(TABNAME_QUESTIONS);
let scroll_position;
const per_page = 20;

const newerIdStorage = function () {
  /** @type {{questions: Array<{id: number, nid: number}>, answers: Array<{id: number, nid: number}>, comments: Array<{id: number, nid: number}>, reviews: Array<{id: number, nid: number, type: string}>}} */
  const idMap = {
    questions: [],
    answers: [],
    comments: [],
    reviews: []
  };
  return {
    get(key) {
      return idMap[key] || [];
    },

    set(key, data) {
      idMap[key] = data;
    },

    checkShouldRefreshState: (
    /** @type {Stores.PublishPageState} */
    state, tabName, newerIds) => {
      const isInFirstPage = state[`${tabName}_pagination`] && state[`${tabName}_pagination`].page === 1;
      const oldIds = state[`${tabName}_newerIds`];
      const isNotSameIds = oldIds.length !== newerIds.length && oldIds.map(item => item.nid).join(',') !== newerIds.map(item => item.nid).join(',');
      return isInFirstPage && isNotSameIds;
    }
  };
}();

const getUserState = () => window.app.getState().user.user;

const checkShouldRefreshState = (
/** @type {Stores.PublishPageState} */
state, tabName, newerIds) => {
  const isInFirstPage = state[`${tabName}_pagination`] && state[`${tabName}_pagination`].page === 1;
  const oldIds = state[`${tabName}_newerIds`];
  const isNotSameIds = oldIds.length !== newerIds.length && oldIds.map(item => item.nid).join(',') !== newerIds.map(item => item.nid).join(',');
  return isInFirstPage && isNotSameIds;
};

const as = {
  /**
   * 加载提问列表、回答列表或文字列表
   */
  getContexts: ({
    tabName,
    page
  }) => state => {
    const params = {
      user_id: getUserState().user_id,
      page,
      per_page,
      include: ['user', 'topics', 'is_following'],
      order: state[`${tabName}_order`]
    };

    if (tabName === TABNAME_QUESTIONS) {
      return getQuestions(params);
    }

    if (tabName === TABNAME_COMMENTS) {
      params.include = ['user', 'voting', 'detail'];
      return getComments(params);
    }

    if (tabName === TABNAME_FOLLOWINGS) {
      params.include = ['user', 'topics', 'is_following'];
      return getMyFollowingQuestions(params);
    }

    params.include = ['user', 'question', 'voting'];
    return getAnswers(params);
  },
  onCreate: () => (state, actions) => {
    emit('route_update');
    actions.setTitle('发表管理');
    tab = new mdui.Tab('.mc-tab');
    tabIndex = tab.activeIndex;
    actions.afterChangeTab(); // 创建的时候, 把更新id设置进去

    actions.setState({
      questions_newerIds: newerIdStorage.get('questions'),
      answers_newerIds: newerIdStorage.get('answers'),
      comments_newerIds: newerIdStorage.get('comments'),
      reviews_newerIds: newerIdStorage.get('reviews')
    }); // 一次性加载所有tab的数据(产品需要显示数量)

    tabs.forEach(tabName => {
      if (state[`${tabName}_pagination`] && !checkShouldRefreshState(state, tabName, newerIdStorage.get(tabName))) {
        return;
      }

      actions.loadPageData({
        tabName,
        page: 1
      });
    });
    tab.$element.on('change.mdui.tab', () => {
      tabIndex = tab.activeIndex;
      window.history.replaceState({}, '', `#${tabs[tabIndex]}`);
      actions.afterChangeTab();
    }); // 恢复滚动条位置

    if (scroll_position) {
      window.scrollTo(0, scroll_position);
      scroll_position = 0;
    }
  },
  afterChangeTab: () => (
  /** @type {Stores.PublishPageState} */
  state, actions) => {
    const tabName = tabs[tabIndex];
    const TAB_NAME = tabName.toUpperCase();
    actions.setState({
      current_tab: tabName
    }); // 可能存在打开编辑器的情况

    actions.editorClose();

    if (state[`${tabName}_pagination`]) {
      return;
    } // 从页面中加载初始数据


    if (window[`G_USER_${TAB_NAME}`]) {
      actions.setState({
        [`${tabName}_data`]: window[`G_USER_${TAB_NAME}`].data,
        [`${tabName}_pagination`]: window[`G_USER_${TAB_NAME}`].pagination
      }); // 清空页面中的数据，下次需要从 ajax 加载

      window[`G_USER_${TAB_NAME}`] = null;
    }
  },
  loadPageData: ({
    tabName,
    page
  }) => (state, actions) => {
    if (tabName === 'reviews') {
      return actions.loadReviews(page);
    }

    actions.setState({
      [`${tabName}_loading`]: true
    });
    return actions.getContexts({
      tabName,
      page
    }).finally(() => {
      actions.setState({
        [`${tabName}_loading`]: false
      });
    }).then(response => {
      actions.setState({
        [`${tabName}_data`]: response.data,
        [`${tabName}_pagination`]: response.pagination
      });
    }).catch(apiCatch);
  },

  /**
   * 点击链接后，保存最后访问的提问ID和提问详情
   * @param context 提问或文字信息
   */
  afterItemClick: context => (state, actions) => {
    const tabName = tabs[tabIndex];
    scroll_position = window.pageYOffset;

    if (tabName === TABNAME_QUESTIONS || tabName === TABNAME_FOLLOWINGS) {
      window.G_QUESTION = context;
      actions.setState({
        last_visit_question_id: context.question_id
      });
      const newerData = state.questions_newerIds.find(item => item.id === context.question_id);

      if (newerData) {
        const nextIds = state.questions_newerIds.filter(item => item.id !== newerData.id);
        actions.setState({
          questions_newerIds: nextIds
        });
        newerIdStorage.set('questions', nextIds);
        window.app.notifications.readOne(newerData.nid);
      }
    } else if (tabName === TABNAME_COMMENTS) {
      window.G_ARTICLE = context;
      actions.setState({
        last_visit_article_id: context.comment_id
      });
      const newerData = state.comments_newerIds.find(item => item.id === context.comment_id);

      if (newerData) {
        const nextIds = state.comments_newerIds.filter(item => item.id !== newerData.id);
        actions.setState({
          comments_newerIds: nextIds
        });
        newerIdStorage.set('comments', nextIds);
        window.app.notifications.readOne(newerData.nid);
      }
    } else {
      window.G_ANSWER = context;
      actions.setState({
        last_visit_answer_id: context.answer_id
      });
      const newerData = state.answers_newerIds.find(item => item.id === context.answer_id);

      if (newerData) {
        const nextIds = state.answers_newerIds.filter(item => item.id !== newerData.id);
        actions.setState({
          answers_newerIds: nextIds
        });
        newerIdStorage.set('answers', nextIds);
        window.app.notifications.readOne(newerData.nid);
      }
    }
  },

  /**
   * 切换排序方式
   */
  changeOrder: order => (state, actions) => {
    const tabName = tabs[tabIndex];

    if (order === state[`${tabName}_order`]) {
      return;
    }

    actions.setState({
      [`${tabName}_order`]: order,
      [`${tabName}_data`]: [],
      [`${tabName}_pagination`]: null
    });
    actions.loadPageData({
      tabName,
      page: 1
    });
  },
  loadReviews: page => (
  /** @type {Stores.PublishPageState} */
  state,
  /** @type {Stores.PublishPageActions} */
  actions) => {
    const userState = getUserState();
    const filterOptions = state.reviewsFilterOptions;
    const reqParams = {
      user_id: userState.user_id,
      page,
      per_page,
      order: state.reviews_order
    };

    if (typeof filterOptions.status === 'number' && filterOptions.status !== -1) {
      reqParams.state = filterOptions.status;
    }

    if (filterOptions.type) {
      reqParams.type = filterOptions.type;
    }

    actions.setState({
      reviews_loading: true
    });
    return getReviewList(reqParams).then(result => {
      /** @type {Stores.PublishPageState['reviews_data']} */
      const data = result.data.map(item => {
        const isComment = item.comment_id;
        const isAnswer = item.answer_id;
        let id;
        let type;
        let title;
        let questionId;
        let commentModalOptions;
        let content;

        if (isComment) {
          id = item.comment_id;
          type = 'comment';
          title = item.content;
          content = item.content;
          commentModalOptions = {
            id: item.commentable_id,
            type: item.commentable_type
          };
        } else if (isAnswer) {
          id = item.answer_id;
          type = 'answer';
          title = item.content_markdown;
          content = item.content_rendered;
          questionId = item.question_id;
          commentModalOptions = {
            id: item.question_id,
            type: 'question'
          };
        } else {
          id = item.question_id;
          type = 'question';
          title = item.title;
          content = item.content_rendered;
        }

        return {
          id,
          title,
          type,
          status: item.state,
          createTime: item.create_time,
          updateTime: item.update_time,
          checked: false,
          questionId,
          commentModalOptions,
          censorId: item.censor_id,
          content,
          editableForFail: item.state !== 2 ? undefined : item.editable
        };
      }); // 如果是全部审核类型, 优先把审核不通过排前面

      if (!filterOptions.type) {
        data.sort((a, b) => b.status - a.status);
      }

      actions.setState({
        reviews_data: data,
        reviews_pagination: result.pagination
      });
    }).finally(() => {
      actions.setState({
        reviews_loading: false
      });
    });
  },
  editReviewBy: ({
    type,
    id,
    content,
    title
  }) => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    let handler;

    if (type === 'question') {
      handler = () => updateQuestion({
        question_id: id,
        title,
        content_rendered: content
      });
    } else if (type === 'answer') {
      handler = () => updateAnswer({
        answer_id: id,
        content_rendered: content
      });
    } else if (type === 'comment') {
      handler = () => updateComment({
        comment_id: id,
        content
      });
    }

    if (!handler) {
      return Promise.reject(new Error('editReviewBy no type matched to handle!'));
    }

    actions.setState({
      isPublishEditingReview: true
    });
    return handler().then(() => {
      // TODO: 回答的内容是html文本, 目前没法直接更新, 所以通过请求接口的方式来更新数据
      if (type === 'answer') {
        actions.loadReviews(state.reviews_pagination.page);
        return;
      }

      actions.setState({
        reviews_data: state.reviews_data.map(item => {
          if (item.id !== id) {
            return item;
          }

          return extend(item, {
            content,
            title: title || content || item.title,
            status: 0
          });
        })
      });
    }).finally(() => {
      actions.setState({
        isPublishEditingReview: false
      });
    });
  },
  deleteReviewBy: ({
    type,
    id
  }) => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    let handler;

    if (type === 'question') {
      handler = () => deleteQuestion({
        question_id: id
      });
    } else if (type === 'answer') {
      handler = () => deleteAnswer({
        answer_id: id
      });
    } else if (type === 'comment') {
      handler = () => deleteComment({
        comment_id: id
      });
    }

    if (!handler) {
      return Promise.reject(new Error('deleteReviewBy no matched type for handle!'));
    }

    return handler().then(() => {
      if (state.reviews_data.length === 1) {
        actions.loadReviews(Math.max(1, state.reviews_pagination.page - 1));
        return;
      }

      actions.setState({
        reviews_data: state.reviews_data.filter(item => item.id !== id),
        reviews_pagination: extend({}, state.reviews_pagination, {
          total: state.reviews_pagination.total - 1
        })
      });
    });
  },
  // 发表了提问/回答/评论后, 需要调用该方法, 让审核tab展示时重新请求来更新数据
  resetReviews: () => (_,
  /** @type {Actions} */
  actions) => {
    actions.setState({
      reviews_data: [],
      reviews_pagination: null
    });
  },
  resetFollowings: () => (_,
  /** @type {Actions} */
  actions) => {
    actions.setState({
      followings_data: [],
      followings_pagination: null
    });
  },
  deleteSelected: () => (
  /** @type {State} */
  state) => {
    const selectedData = state.reviews_data.filter(item => item.checked).reduce((acc, curr) => {
      if (curr.type === 'question') {
        acc.questions.push(curr.id);
        return acc;
      }

      if (curr.type === 'answer') {
        acc.answers.push(curr.id);
        return acc;
      }

      if (curr.type === 'comment') {
        acc.comments.push(curr.id);
        return acc;
      }

      return acc;
    }, {
      questions: [],
      answers: [],
      comments: []
    });

    const questionTask = () => {
      if (selectedData.questions.length === 0) {
        return Promise.resolve();
      }

      return deleteQuestion({
        question_id: selectedData.questions.join(',')
      });
    };

    const answerTask = () => {
      if (selectedData.answers.length === 0) {
        return Promise.resolve();
      }

      return deleteAnswer({
        answer_id: selectedData.answers.join(',')
      });
    };

    const commentTask = () => {
      if (selectedData.comments.length === 0) {
        return Promise.resolve();
      }

      return deleteComment({
        comment_id: selectedData.comments.join(',')
      });
    };

    return Promise.all([questionTask(), answerTask(), commentTask()]);
  },
  processNewerNotifications: notifications => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    const newerInfo = notifications.reduce((acc, curr) => {
      if (curr.type === 'question_pass') {
        acc.questionIds.push({
          id: curr.question_id,
          nid: curr.notification_id
        });
        return acc;
      }

      if (curr.type === 'answer_pass') {
        acc.answerIds.push({
          id: curr.answer_id,
          nid: curr.notification_id
        });
        return acc;
      }

      if (curr.type === 'comment_pass') {
        acc.commentIds.push({
          id: curr.comment_id,
          nid: curr.notification_id
        });
        return acc;
      }

      if (curr.type === 'question_reject') {
        acc.reviewIds.push({
          type: 'question',
          id: curr.question_id,
          nid: curr.notification_id
        });
        return acc;
      }

      if (curr.type === 'answer_reject') {
        acc.reviewIds.push({
          type: 'answer',
          id: curr.answer_id,
          nid: curr.notification_id
        });
        return acc;
      }

      if (curr.type === 'comment_reject') {
        acc.reviewIds.push({
          type: 'comment',
          id: curr.comment_id,
          nid: curr.notification_id
        });
        return acc;
      }

      return acc;
    }, {
      questionIds: [],
      answerIds: [],
      commentIds: [],
      reviewIds: []
    });
    newerIdStorage.set('questions', newerInfo.questionIds);
    newerIdStorage.set('answers', newerInfo.answerIds);
    newerIdStorage.set('comments', newerInfo.commentIds);
    newerIdStorage.set('reviews', newerInfo.reviewIds); // 当用户在发布管理页才更新, 防止在其他页面也触发更新, 导致patch的问题

    if (!window.location.pathname.includes('/publish')) {
      return;
    }

    const shouldRefrechQuestions = checkShouldRefreshState(state, 'questions', newerInfo.questionIds);
    const shouldRefrechAnswers = checkShouldRefreshState(state, 'answers', newerInfo.answerIds);
    const shouldRefreshComments = checkShouldRefreshState(state, 'comments', newerInfo.commentIds);
    const shouldRefreshReviews = checkShouldRefreshState(state, 'reviews', newerInfo.reviewIds) || state.reviews_pagination && state.reviews_pagination.page === 1 && ['questionIds', 'answerIds', 'commentIds'].some(key => newerInfo[key].length > 0);

    if (shouldRefrechQuestions) {
      actions.loadPageData({
        tabName: 'questions',
        page: 1
      });
    }

    if (shouldRefrechAnswers) {
      actions.loadPageData({
        tabName: 'answers',
        page: 1
      });
    }

    if (shouldRefreshComments) {
      actions.loadPageData({
        tabName: 'comments',
        page: 1
      });
    }

    if (shouldRefreshReviews) {
      actions.loadReviews(1);
    }

    actions.setState({
      questions_newerIds: newerInfo.questionIds,
      answers_newerIds: newerInfo.answerIds,
      comments_newerIds: newerInfo.commentIds,
      reviews_newerIds: newerInfo.reviewIds
    });
  }
};
const actions = extend(as, commonActions, userPopoverActions, editorActions, detailDialogActions);
const oldEdiorClose = actions.editorClose;

actions.editorClose = function needSpecHandleEditor() {
  return (_, a) => {
    a.setState({
      editingReview: undefined // 原因参见渲染文件

    });
    const fn = oldEdiorClose();
    return fn(_, a);
  };
};

export default actions;