import { h } from 'hyperapp';
import currentUser from '~/utils/currentUser';
import { emit } from '~/utils/pubsub';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Item from './components/item/view.jsx';
export default ((state, actions) => ({
  match
}) => {
  const isEmpty = !state.loading && !state.data.length && state.pagination;
  const isLoaded = !state.loading && state.pagination && state.pagination.page === state.pagination.pages;
  const isLoading = state.loading;
  return h("div", {
    oncreate: actions.onCreate,
    ondestroy: actions.onDestroy,
    key: match.url,
    id: "page-notifications",
    class: "mdui-container"
  }, currentUser() ? [h(Empty, {
    show: isEmpty,
    title: "\u6CA1\u6709\u4F60\u7684\u901A\u77E5",
    description: "\u6B64\u5904\u4F1A\u663E\u793A\u4F60\u6240\u6709\u7684\u5DF2\u8BFB\u548C\u672A\u8BFB\u901A\u77E5",
    key: "0"
  }), state.data.length ? h("div", {
    class: "item-list mdui-card",
    key: "1"
  }, state.data.map(notification => h(Item, {
    notification: notification,
    actions: actions
  }))) : null, !currentUser() ? h(Empty, {
    show: true,
    title: "\u767B\u9646\u540E\u624D\u80FD\u67E5\u770B\u901A\u77E5",
    description: "\u767B\u9646\u540E\uFF0C\u4F60\u7684\u901A\u77E5\u6D88\u606F\u5C06\u4F1A\u663E\u793A\u5728\u6B64\u5904",
    action: () => {
      emit('login_open');
    },
    action_text: "\u767B\u5F55",
    key: "2"
  }) : null, h(Loading, {
    show: isLoading,
    key: "3"
  }), h(Loaded, {
    show: isLoaded,
    key: "4"
  })] : null);
});