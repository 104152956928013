import { h } from 'hyperapp';
import cc from 'classcat';
import './index.less';
export default (({
  review_status
}) => {
  // 根据审核的状态码返回对应的类型
  const type = ['wait', 'success', 'fail']; // 返回对应类型的class以及icon

  const typeObeject = {
    wait: {
      // TODO:现在还没有未审核的 以后有需要这里加
      icon: 'wait',
      class: 'review-tips-wait',
      labe: '待审核'
    },
    success: {
      icon: 'done',
      class: 'review-tips-success',
      label: '审核通过'
    },
    fail: {
      icon: 'clear',
      class: 'review-tips-fail',
      label: '审核不通过'
    }
  };
  const currentType = type[review_status];
  return h("div", {
    class: cc(['review-tips-container', typeObeject[currentType].class])
  }, h("i", {
    class: "mdui-icon material-icons fail-icon"
  }, typeObeject[currentType].icon), h("span", {
    class: "review-tips-font"
  }, typeObeject[currentType].label));
});