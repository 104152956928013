import extend from 'mdui.jq/es/functions/extend';
import mdui from 'mdui';
import { unescape } from 'html-escaper';
import { get as getQuestion, update as updateQuestion, del as deleteQuestion } from 'mdclub-sdk-js/es/QuestionApi';
import { get as getAnswer, update as updateAnswer, del as deleteAnswer } from 'mdclub-sdk-js/es/AnswerApi';
import { get as getComment, update as updateComment, del as deleteComment } from 'mdclub-sdk-js/es/CommentApi';
import topicSelectorActions from '~/components/editor/components/topic-selector/actions';
import settingToastActions from '~/components/setting-edit/actions.js';
import commonActions from '~/utils/actionsAbstract';
import { getReviewFailInfo, examineReview, getReasonList } from '~/apis/reviews';
import { getCommentDetail } from '~/apis/comments';
import editorActions from '~/components/editor/actions';
/** @typedef {Stores.ReviewDetailState} State */

/** @typedef {Stores.ReviewDetailActions} Actions */

let username;
const as = {
  /**
   * 加载问题数据
   */
  loadQuestion: ({
    questionId,
    reviewStatus = -1
  }) => (state,
  /** @type {Actions} */
  actions) => {
    actions.setState({
      isLoading: true
    });
    return getQuestion({
      question_id: questionId,
      include: ['user', 'topics', 'is_following', 'voting']
    }).finally(() => {
      actions.setState({
        isLoading: false
      });
    }).then(({
      data
    }) => {
      const relationUser = data.relationships ? data.relationships.user : {}; // 和官网以及产品的名称统一

      username = relationUser.nickname || relationUser.username;
      actions.setState({
        question: {
          title: unescape(data.title),
          username,
          userAvatar: relationUser.avatar || null,
          datetime: data.update_time,
          content: data.content_rendered,
          content_markdown: data.content_markdown,
          status: reviewStatus,
          hasLoaded: true,
          editable: data.editable
        }
      });
    });
  },

  /**
   * 加载回答数据
   */
  loadAnswer: ({
    answerId,
    reviewStatus = -1
  }) => (state,
  /** @type {Actions} */
  actions) => {
    actions.setState({
      isLoading: true
    });
    return getAnswer({
      answer_id: answerId,
      include: ['user', 'voting', 'question']
    }).finally(() => {
      actions.setState({
        isLoading: false
      });
    }).then(({
      data
    }) => {
      const relationUser = data.relationships ? data.relationships.user : {}; // 和官网以及产品的名称统一

      username = relationUser.nickname || relationUser.username;
      actions.setState({
        answer: {
          title: '',
          username,
          userAvatar: relationUser.avatar || null,
          datetime: data.update_time,
          content: data.content_rendered,
          content_markdown: data.content_markdown,
          status: reviewStatus,
          hasLoaded: true,
          question_title: data.relationships.question.title,
          editable: data.editable
        }
      });
    });
  },
  loadComment: ({
    commentId,
    reviewStatus = -1
  }) => (state,
  /** @type {Actions} */
  actions) => {
    actions.setState({
      isLoading: true
    });
    return getComment({
      comment_id: commentId,
      include: ['user']
    }).finally(() => {
      actions.setState({
        isLoading: false
      });
    }).then(({
      data
    }) => {
      const relationUser = data.relationships ? data.relationships.user : {}; // 和官网以及产品的名称统一

      username = relationUser.nickname || relationUser.username;
      actions.setState({
        comment: {
          title: '',
          username,
          userAvatar: relationUser.avatar || null,
          datetime: data.update_time,
          content: data.content,
          content_markdown: data.content,
          status: reviewStatus,
          hasLoaded: true,
          editable: data.editable
        }
      });
    });
  },
  loadReviewStatus: ({
    reviewType,
    censorId
  }) => (_,
  /** @type {Actions} */
  actions) => {
    return getReviewFailInfo({
      id: censorId,
      type: reviewType
    }).then(result => {
      actions.setState({
        reviewInfo: {
          status: result.state,
          failReply: result.state === 2 ? {
            username: '管理员',
            userAvatar: '',
            datetime: result.censor_time,
            content: `<div>
              <p class="__reason_line__">原因如下：</p>
              <ol>
                ${result.reasons.map(reason => `<li>${reason}</li>`).join('')}
              </ol>
            </div>`
          } : {
            reviewInfo: {
              status: result.state,
              failReply: {
                username: '',
                userAvatar: '',
                datetime: 0,
                content: ''
              }
            }
          }
        }
      });
    });
  },
  loadCommentRelativeTopData: ({
    commentId
  }) => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    return getCommentDetail(commentId).then(detail => {
      if (detail.commentable_type === 'answer') {
        return actions.loadAnswer({
          answerId: detail.commentable_id
        }).then(res => {
          actions.setState({
            commentTopType: detail.commentable_type
          });
          return res;
        });
      }

      if (detail.commentable_type === 'question') {
        return actions.loadQuestion({
          questionId: detail.commentable_id
        }).then(res => {
          actions.setState({
            commentTopType: detail.commentable_type
          });
          return res;
        });
      }

      return null;
    });
  },
  editReviewBy: ({
    type,
    id,
    content,
    title
  }) => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    let handler;

    if (type === 'question') {
      handler = () => updateQuestion({
        question_id: id,
        title,
        content_rendered: content
      });
    } else if (type === 'answer') {
      handler = () => updateAnswer({
        answer_id: id,
        content_rendered: content
      });
    } else if (type === 'comment') {
      handler = () => updateComment({
        comment_id: id,
        content
      });
    }

    if (!handler) {
      return Promise.reject(new Error('editReviewBy no type matched to handle!'));
    }

    actions.setState({
      isPublishEditingReview: true
    });
    return handler().then(() => {
      const nextState = {
        reviewInfo: {
          status: 0,
          failReply: {
            username: '',
            userAvatar: '',
            datetime: 0,
            content: ''
          }
        },
        [type]: extend(state[type], {
          title: title || state[type].title,
          content
        })
      };
      actions.setState(nextState);
    }).finally(() => {
      actions.setState({
        isPublishEditingReview: false
      });
    });
  },
  deleteReviewBy: ({
    type,
    id
  }) => () => {
    let handler;

    if (type === 'question') {
      handler = () => deleteQuestion({
        question_id: id
      });
    } else if (type === 'answer') {
      handler = () => deleteAnswer({
        answer_id: id
      });
    } else if (type === 'comment') {
      handler = () => deleteComment({
        comment_id: id
      });
    }

    if (!handler) {
      return Promise.reject(new Error('deleteReviewBy no matched type for handle!'));
    }

    return handler();
  },
  examineReview: params => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    actions.setState({
      isConfirming: true
    });
    return examineReview(params).finally(() => {
      actions.setState({
        isConfirming: false
      });
    });
  },
  loadReasonList: () => (
  /** @type {State} */
  state,
  /** @type {Actions} */
  actions) => {
    const font_selections = [];
    const other_selections = [];
    const img_selections = [];
    return getReasonList().then(result => {
      result.forEach(item => {
        if (item.category === 'text') {
          font_selections.push({
            id: item.id,
            text: item.reason
          });
        }

        if (item.category === 'other') {
          other_selections.push({
            id: item.id,
            text: item.reason
          });
        }

        if (item.category === 'image') {
          img_selections.push({
            id: item.id,
            text: item.reason
          });
        }
      });
      actions.setState({
        font_selections,
        other_selections,
        img_selections
      });
    });
  },
  // TODO: 后面再使用高阶函数来处理下面的选择器逻辑
  // 改变不通过弹窗的文字原因下拉选择器
  onChangeFontSelector: item => (state, actions) => {
    actions.setState({
      font_reason_id: item.id
    });
  },
  // 改变不通过弹窗的图片原因下拉选择器
  onChangeImgSelector: item => (state, actions) => {
    actions.setState({
      img_reason_id: item.id
    });
  },
  // 改变不通过弹窗的其他原因下拉选择器
  onChangeOtherSelector: item => (state, actions) => {
    actions.setState({
      other_reason_id: item.id
    });
  },
  // 通过/不通过弹窗cancel
  cancel: () => (state, actions) => {
    actions.setState({
      font_reason_id: 0,
      img_reason_id: 0,
      other_reason_id: 0,
      setting_open: false,
      editor_selected_topics: [],
      isSetting: false
    });
  },
  // 弹窗submit事件
  submit: ({
    type,
    id
  }) => (state, actions) => {
    const reason_list_id = [];

    if (state.font_reason_id) {
      reason_list_id.push(state.font_reason_id);
    }

    if (state.img_reason_id) {
      reason_list_id.push(state.img_reason_id);
    }

    if (state.other_reason_id) {
      reason_list_id.push(state.other_reason_id);
    }

    if (state.isSetting && !state.editor_selected_topic_ids.length) {
      mdui.snackbar('请至少选择一个问题分类');
      return;
    }

    const currenReviewStatus = state.checkbox_review_status ? state.checkbox_review_status : state.currentStatus;

    if (currenReviewStatus === 2 && !reason_list_id.length && !state.isSetting) {
      mdui.snackbar('请选择原因');
      return;
    }

    actions.examineReview({
      type,
      state: currenReviewStatus,
      reason_id: reason_list_id,
      id,
      topic_ids: state.editor_selected_topic_ids
    }).then(() => {
      actions.cancel();
      mdui.snackbar('处理成功');
      setTimeout(() => {
        window.location.href = `/admin/reviews/${type}`;
      }, 1000);
    }).catch(err => {
      mdui.snackbar(err.message);
    });
  },
  resetState: () => (_,
  /** @type {Actions} */
  actions) => {
    actions.setState({
      question: {},
      answer: {},
      comment: {},
      reviewInfo: {
        status: -1,
        failReply: {
          username: '',
          userAvatar: '',
          datetime: 0,
          content: ''
        }
      },
      font_reason_id: 0,
      img_reason_id: 0,
      other_reason_id: 0,
      setting_open: false,
      commentTopType: ''
    });
  }
};
export default extend({}, as, commonActions, editorActions, settingToastActions, topicSelectorActions);