import { h } from 'hyperapp';
export default (({
  iconTitle,
  icon,
  onClick
}) => {
  return h("i", {
    class: "mdui-icon material-icons",
    title: iconTitle,
    onclick: onClick
  }, icon);
});