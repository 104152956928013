/**
 * 在需要用到编辑器的页面中，引入该 state
 */
export default {
  // 是否打开编辑器
  editor_open: false,
  // 是否最大化编辑器
  editor_maximize: false,
  // 是否最小化编辑器
  editor_minimize: false,
  // 编辑器中已选中的话题
  editor_selected_topics: [],
  // 已选中的话题ID数组
  editor_selected_topic_ids: [],
  // 首页编辑器是否最小化
  editor_index_minimize: false
};