import each from 'mdui.jq/es/functions/each';
export const findIndex = (items, prop, value) => {
  let result;
  each(items, (index, item) => {
    if (item[prop] === value) {
      result = index;
      return false;
    }

    return true;
  });
  return result;
};
export const findItem = (items, prop, value) => {
  const index = findIndex(items, prop, value);
  return items[index];
};
export function getLocationQuery() {
  const paramsArray = window.location.search.substr(1).split('&');
  const params = {}; // eslint-disable-next-line no-plusplus

  for (let i = 0; i < paramsArray.length; ++i) {
    const param = paramsArray[i].split('=', 2);
    if (param.length !== 2) continue; // eslint-disable-line no-continue

    params[param[0]] = decodeURIComponent(param[1].replace(/\+/g, ' '));
  }

  return params;
}