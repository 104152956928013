import { h } from 'hyperapp';
import './index.less';
import cc from 'classcat';
import { requireStatic } from '~/utils/requireStatic';
export default (({
  avatar,
  title,
  content,
  isContainImg,
  isContainLink
}, children) => h("div", {
  class: cc(['question-line', title ? '' : 'comment-line'])
}, avatar ? h("div", {
  class: "avatar user-popover-trigger",
  style: {
    backgroundImage: `url("${avatar || requireStatic(require('~/images/default-user.png'))}")`
  }
}) : null, !avatar ? h("i", {
  class: "mdui-icon material-icons default-avatar"
}, "account_circle") : null, title ? h("div", {
  class: " title headline user-popover-trigger mdui-text-color-theme-text"
}, title) : null, h("div", {
  class: cc(['question-line-footer headline mdui-text-color-theme-secondary', title ? '' : 'comment-content'])
}, h("span", {
  class: "mdui-text-color-theme-secondary headline"
}, content), h("div", {
  class: "icon-group"
}, isContainImg ? h("i", {
  class: "mdui-icon material-icons"
}, "image") : null, isContainLink ? h("i", {
  class: "mdui-icon material-icons"
}, "link") : null)), children));