import { h } from 'hyperapp';
import './index.less';
export default (({
  cancel,
  submit
}) => {
  return h("div", {
    class: "table-actions"
  }, h("button", {
    class: "mdui-btn mdui-ripple xk-button-cancel xk-button-size",
    onclick: cancel
  }, "\u53D6\u6D88"), h("button", {
    class: "mdui-btn mdui-ripple xk-button-submit xk-button-size",
    onclick: submit
  }, "\u786E\u5B9A"));
});