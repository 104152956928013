import { h } from 'hyperapp';
import $ from 'mdui.jq';
import { plainText } from '~/utils/html';
import './index.less';
import UserLine from '~/components/user-line/view.jsx';
import Vote from '~/components/vote/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
export default (({
  comment,
  commentIndex,
  actions,
  user
}) => h("div", {
  class: "item",
  key: comment.comment_id,
  oncreate: element => $(element).mutation()
}, h(UserLine, {
  actions: actions,
  user: comment.relationships.user,
  time: comment.create_time,
  dataName: `comments_data[${commentIndex}].replies_data`,
  primaryKey: "comment_id",
  primaryValue: comment.comment_id
}), h("div", {
  class: "content mdui-typo",
  oncreate: plainText(comment.content),
  onupdate: plainText(comment.content)
}), user ? h("div", {
  class: "actions"
}, h(Vote, {
  actions: actions,
  item: comment,
  commentIndex: commentIndex,
  type: "replies"
}), h("div", {
  class: "flex-grow"
}), h(OptionsButton, {
  type: "comment",
  item: comment
})) : null));