export default {
  detailDialog: {
    title: '',
    username: '',
    userAvatar: '',
    datetime: 0,
    content: '',
    isLoading: false,
    isLoaded: false
  }
};