import { h } from 'hyperapp';
import './index.less';
import Empty from '~/components/empty/view.jsx';
import Loading from '~/components/loading/view.jsx';
import Editor from '~/components/editor/view.jsx';
import { QuestionItem } from '~/components/list-item/view.jsx';
import MenuSelection from '~/components/menu-selection/view.jsx';
import SearchBar from '~/components/appbar/components/toolbar/components/search-bar/view.jsx';
import SearchIcon from '~/components/appbar/components/toolbar/components/search-icon/view.jsx';
export default ((state, actions) => ({
  match
}) => {
  const {
    loading,
    question,
    pagination
  } = state;
  const isEmpty = !loading && !question.length && pagination;
  const orderSelectionList = [{
    id: 'update_time',
    text: '最新问题(升序)'
  }, {
    id: '-update_time',
    text: '最新问题(降序)'
  }, {
    id: 'answer_count',
    text: '最多问答(升序)'
  }, {
    id: '-answer_count',
    text: '最多问答(降序)'
  }];
  const categorySelectionList = [];
  let category = {};

  if (state.topics.length > 0) {
    state.topics.forEach(item => {
      category = {};
      category.id = item.topic_id;
      category.text = item.name;
      categorySelectionList.push(category);
    });
  }

  return h("div", {
    class: "mdui-container container",
    key: match.url,
    oncreate: element => actions.onCreate(element),
    ondestroy: actions.onDestroy
  }, h(Editor, {
    id: "1",
    title: "\u65B0\u63D0\u95EE",
    withTitle: true,
    isIndex: true,
    onSubmit: actions.onSubmit,
    publishing: state.submitting,
    state: state,
    actions: actions,
    className: 'editor',
    isKeyboard: true,
    bodyClassName: "index-editor-body",
    editor_index_minimize: state.editor_index_minimize
  }), h("div", {
    class: "mdui-card card-container"
  }, h("div", {
    class: "question-header"
  }, h("div", {
    className: "search"
  }, h(SearchIcon, null), h(SearchBar, null)), h("div", {
    class: "question-buttons"
  }, h(MenuSelection, {
    selections: categorySelectionList,
    key: "category",
    activeId: state.topic_id,
    onChange: actions.changeQuestionCategoryOrder
  }), h(MenuSelection, {
    selections: orderSelectionList,
    key: "time",
    activeId: state.order,
    iconSize: 18,
    isSvg: true,
    onChange: actions.changeQuestionOrder
  }))), state.question && state.question.length ? state.question.map((item, index) => h(QuestionItem, {
    question: item // 将索引从0改为从1开始
    ,
    question_index: index + 1,
    last_visit_id: state.last_visit_id,
    actions: actions
  })) : null, h(Empty, {
    show: isEmpty,
    title: "\u6CA1\u6709\u66F4\u591A\u70ED\u70B9\u95EE\u9898"
  }), h(Loading, {
    show: loading
  })));
});