import { h } from 'hyperapp';
import './index.less';
/**
 *
 * @param {Components.ButtonBaseComponentProps} param0
 * @returns
 */

export default function ButtonBase({
  className = '',
  type = 'default',
  width,
  text,
  onClick,
  disabled
}, children) {
  return h("button", {
    className: `mdui-btn mdui-ripple mc-btn btn-${type} ${className}`,
    style: width ? `width: ${width}px` : '',
    onclick: onClick,
    disabled: disabled
  }, text || children);
}