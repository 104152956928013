import { h } from 'hyperapp';
import { timeFormat } from '~/utils/time';
import { richText } from '~/utils/html';
import { requireStatic } from '~/utils/requireStatic';
import TagStatus from '~/components/tag-status/view.jsx';
import './index.less';
/**
 *
 * @param {Components.ReviewFailReplyComponentProps} param0
 */

export default function ({
  className = '',
  username,
  userAvatar,
  replyDatetime,
  replyContent,
  key,
  editable
}) {
  return h("div", {
    key: key,
    className: `mc-review-fail-reply ${className || ''}`
  }, h("div", {
    className: "g-flex g-justify-content-between g-align-items-center"
  }, h("div", {
    className: "g-flex g-flex-1 g-align-items-center"
  }, h("div", {
    className: "avatar-container"
  }, h("span", {
    className: `user-img ${!userAvatar ? 'admin-img' : ''}`
  }, h("img", {
    src: userAvatar || requireStatic(require('~/images/default-admin.jpg'))
  }))), h("div", {
    className: "official-container"
  }, h("p", {
    className: "g-flex g-align-items-center"
  }, h(TagStatus, {
    className: "official-tag",
    text: "\u5B98\u65B9"
  }), username), h("p", {
    className: "grey"
  }, "\u62B1\u6B49\uFF01\u4E8E", timeFormat(replyDatetime, 'YYYY年MM月DD日'), "\u5BA1\u6838\u4E0D\u901A\u8FC7\uFF1A"))), h("div", {
    class: "mdui-chip review-chip"
  }, h("span", {
    class: "mdui-chip-icon"
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "close")), h("span", {
    class: "mdui-chip-title"
  }, "\u5BA1\u6838\u4E0D\u901A\u8FC7"))), h("div", {
    className: "content-align mdui-typo",
    oncreate: richText(replyContent)
  }), editable ? h("div", {
    className: "grey content-align"
  }, "\u8BF7\u4FEE\u6539\u4EE5\u4E0A\u5185\u5BB9\uFF0C\u91CD\u65B0\u53D1\u5E03\uFF01") : null);
}