import { h } from 'hyperapp';
import './index.less';
export default function ({
  className = '',
  text,
  status = 'success'
}) {
  return h("span", {
    className: `mc-tag-status ${className} ${status}`
  }, text);
}