/**
 * 跳转网页
 */
export function forward(target, redirect = '') {
  let href = `${target}`;

  if (redirect) {
    window.location.href = `${target}`;
    href += `&redirect=${redirect}`;
  }

  window.location.href = href;
}
/**
 * 生成相对官网的url
 */

export function returnUrl(currentRelativeUrl) {
  const environment = process.env.NODE_ENV;

  if (environment === 'production') {
    return window.location.host.indexOf('test') > 0 ? `//test2.xk.design${currentRelativeUrl}` : `//www.xk.design${currentRelativeUrl}`;
  }

  return `http://localhost:3000${currentRelativeUrl}`;
}