import { getRequest, postRequest } from 'mdclub-sdk-js/es/util/requestAlias';
import { buildURL } from 'mdclub-sdk-js/es/util/requestHandler';
/**
 *
 * @param {Apis.ReqGetReviewList} params
 * @returns {Promise<Apis.ResGetReviewList>}
 */

export function getReviewList(params) {
  return getRequest(buildURL('/users/{user_id}/censors', params, ['user_id', 'type', 'state', 'page', 'per_page', 'order'])).then(res => {
    if (res.code !== 0) {
      return Promise.reject(new Error('getReviewList error'));
    }

    return {
      data: res.data,
      pagination: res.pagination
    };
  });
}
/**
 *
 * @param {Apis.ReqGetReviewDetail} params
 * @returns {Promise<Apis.ResGetReviewDetail>}
 */

export function getReviewFailInfo(params) {
  return getRequest(buildURL('/censor/detail', params, ['type', 'id'])).then(res => {
    if (res.code !== 0) {
      return Promise.reject(new Error('getReviewFailInfo error'));
    }

    return res.data;
  });
}
/**
 *
 * @param {Apis.ReqExamineReview} params
 * @returns {Promise<void>}
 */

export function examineReview(params) {
  return postRequest('/censor/examine', params).then(res => {
    if (res.code !== 0) {
      return Promise.reject(new Error('examineReview error'));
    }

    return undefined;
  });
}
/**
 *
 * @returns {Promise<Apis.ResGetReasonList>}
 */

export function getReasonList() {
  return getRequest('/censor/reason').then(res => {
    if (res.code !== 0) {
      return Promise.reject(new Error('getReasonList error'));
    }

    return res.data;
  });
}