import { h } from 'hyperapp';
import cc from 'classcat';
import './index.less';
import Avatar from '~/components/avatar/view.jsx';
import Loading from '~/components/loading/view.jsx';
export default (({
  state
  /** actions, is_me */

}) => {
  const {
    interviewee
  } = state;
  let name;
  let nickname;
  let username;
  let avatar;

  if (interviewee) {
    nickname = interviewee.nickname;
    username = interviewee.username;
    avatar = interviewee.avatar;
    name = nickname || username;
  }

  return h("div", {
    key: "user",
    class: "user mdui-card"
  }, h(Loading, {
    show: state.loading
  }), interviewee ? h("div", {
    class: "info"
  }, h(Avatar, {
    containerClassName: "avatar-box",
    imgClassName: "avatar",
    isShowName: false,
    avatar: avatar,
    nickname: nickname,
    username: username
  }), h("div", {
    class: cc(['profile', {
      fold: state.profile_fold
    }])
  }, h("div", {
    class: "meta username mdui-text-color-theme-text"
  }, name))) : null);
});