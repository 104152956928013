import $ from 'mdui.jq';
import extend from 'mdui.jq/es/functions/extend';
import commonActions from '~/utils/actionsAbstract';
const as = {
  /**
   * 打开设置
   */
  settingOpen: () => (_, actions) => {
    actions.setState({
      setting_open: true
    });
    actions.settingUpdateOverlay();
  },

  /**
   * 弹窗缩小
   */
  settingMinimize: () => (state, actions) => {
    actions.setState({
      setting_minimize: !state.setting_minimize
    });
    actions.settingUpdateOverlay();
  },

  /**
   * 弹窗关闭
   */
  settingClose: () => (_, actions) => {
    actions.setState({
      setting_open: false
    });
    $.hideOverlay();
  },

  /**
   * 最大化弹窗
   */
  settingMaximize: () => (state, actions) => {
    actions.setState({
      setting_minimize: false,
      setting_maximize: !state.setting_maximize
    });
    actions.settingUpdateOverlay();
  },

  /**
   * 全屏显示编辑器时，需要显示遮罩，否则隐藏遮罩
   */
  settingUpdateOverlay: () => (state, actions) => {
    if (state.setting_open && state.setting_maximize && !state.setting_minimize) {
      $.showOverlay();
    } else {
      $.hideOverlay();
    } // TODO: 这个遮罩的逻辑有点问题，先用原来的逻辑
    // if (state.setting_minimize) {
    //   $.hideOverlay();
    // } else {
    //   $.showOverlay();
    // }

  },
  // 取消按钮 提交按钮submit在自己需要用到的地方写方法就可以了，使用的时候需要在页面的actions引入该actions
  settingCancel: () => (_, actions) => {
    actions.setState({
      setting_open: false,
      setting_object: {},
      setting_selected_topic_ids: [],
      setting_selected_topics: []
    });
    $.hideOverlay();
  }
};
export default extend(as, commonActions);