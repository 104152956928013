import { h } from 'hyperapp';
import { emit } from '~/utils/pubsub';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Follow from '~/components/follow/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
import { summaryText } from '~/utils/html';
export default (({
  topic,
  loading,
  actions
}) => h("div", {
  class: "mdui-card topic"
}, topic ? [h("div", {
  class: "info",
  key: "0"
}, h("div", {
  class: "cover",
  style: {
    backgroundImage: `url("${topic.cover.small}")`
  }
}), h("div", {
  class: "main"
}, h("div", {
  class: "name",
  oncreate: summaryText(topic.name),
  onupdate: summaryText(topic.name)
}), h("div", {
  class: "meta mdui-text-color-theme-secondary"
}, h("span", null, topic.question_count, " \u4E2A\u63D0\u95EE")), h("div", {
  class: "description mdui-text-color-theme-secondary",
  oncreate: summaryText(topic.description),
  onupdate: summaryText(topic.description)
}))), h("div", {
  class: "actions",
  key: "1"
}, h(Follow, {
  item: topic,
  type: "topic",
  actions: actions
}), h("div", {
  class: "flex-grow"
}), h(OptionsButton, {
  type: "topic",
  item: topic,
  extraOptions: [{
    name: `查看 ${topic.follower_count} 位关注者`,
    onClick: () => {
      emit('users_dialog_open', {
        type: 'topic_followers',
        id: topic.topic_id
      });
    }
  }]
}))] : null, h(Loading, {
  show: loading
})));