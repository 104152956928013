import { h } from 'hyperapp';
import cc from 'classcat';
import { isUndefined } from 'mdui.jq/es/utils';
import './index.less';
export default (({
  items,
  centered = false,
  key = null
}) => h("div", {
  key: key,
  class: cc(['mc-tab', 'mdui-tab', {
    'mdui-tab-centered': centered
  }])
}, items.map(item => {
  if (!item) {
    return null;
  }

  return h("a", {
    href: `#${item.hash}`,
    class: "mdui-ripple"
  }, item.name, !isUndefined(item.count) ? h("span", null, item.count) : null, item.dot ? h("i", {
    className: "mc-tab-dot"
  }) : null);
})));