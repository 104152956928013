import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import { timeFormat, timeFriendly } from '~/utils/time';
import { fullPath } from '~/utils/path';
import './index.less';
import Avatar from '~/components/avatar/view.jsx';
import UserPopover from '~/components/user-popover/view.jsx';
/**
 * @param actions
 * @param user 用户信息
 * @param time 时间戳
 * @param dataName state 中的数据字段名
 * @param primaryKey
 * @param primaryValue
 */

export default (({
  actions,
  user,
  time,
  dataName = null,
  primaryKey = null,
  primaryValue = null
}) => {
  let avatar;
  let nickname;
  let username;

  if (user) {
    avatar = user.avatar;
    nickname = user.nickname;
    username = user.username;
  }

  return h("div", {
    class: "mc-user-line"
  }, h(UserPopover, {
    actions: actions,
    user: user,
    dataName: dataName,
    primaryKey: primaryKey,
    primaryValue: primaryValue
  }, h(Link, {
    class: "user-popover-trigger",
    to: fullPath(`/users/${user.user_id}`)
  }, h(Avatar, {
    avatar: avatar,
    nickname: nickname,
    username: username,
    userNameClassName: "username"
  })), time ? h("div", {
    class: "more"
  }, h("span", {
    class: "time mdui-text-color-theme-secondary",
    title: timeFormat(time)
  }, timeFriendly(time))) : null));
});