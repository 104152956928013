/* eslint-disable import/no-duplicates */
import extend from 'mdui.jq/es/functions/extend';
import { getList as getQuestions } from 'mdclub-sdk-js/es/QuestionApi';
import { getList as getTopics } from 'mdclub-sdk-js/es/TopicApi';
import { create as createQuestion } from 'mdclub-sdk-js/es/QuestionApi';
import { isUndefined } from 'mdui.jq/es/utils';
import $ from 'mdui.jq';
import { $window } from 'mdui/es/utils/dom';
import mdui from 'mdui';
import { location } from 'hyperapp-router';
import apiCatch from '~/utils/errorHandler';
import editorActions from '~/components/editor/actions';
import commonActions from '~/utils/actionsAbstract';
import userPopoverActions from '~/components/user-popover/actions';
import { emit } from '~/utils/pubsub';
import { fullPath, isPathIndex } from '~/utils/path';
let scroll_position;
/**
 * 类型标题省略展示
 */

const ellipsisTitle = title => {
  if (title.length > 8) {
    return `${title.substring(0, 9)}...`;
  }

  return title;
}; // 初始化编辑器


const initEditor = auto_save_key => {
  // 清空编辑的内容
  window.localStorage.removeItem(`${auto_save_key}-title`);
  window.localStorage.removeItem(`${auto_save_key}-content`); // 清空编辑器状态

  $('.editor-title[placeholder="请输入问题标题"]').get(0).value = '';
  $('.editor-content[placeholder="请输入问题内容"]').get(0).innerHTML = `<p><br /></p>`;
}; // 初始化搜索框


const initSearchBar = () => {
  //
  if (isPathIndex() && $('.search').hasClass('mobile')) {
    $('.search').removeClass('mobile');
  }
};

const as = {
  afterItemClick: item => {
    scroll_position = window.pageYOffset;

    if (!isUndefined(item.topic_id)) {
      window.G_TOPIC = item;
    } else if (!isUndefined(item.question_id)) {
      window.G_QUESTION = item;
    } else if (!isUndefined(item.article_id)) {
      window.G_ARTICLE = item;
    }
  },

  /**
   * 初始化
   */
  onCreate: element => (state, actions) => {
    // 触发路由更新时间
    emit('route_update');
    actions.setTitle(''); // 初始化state

    actions.setState({
      topic_id: 0,
      editor_index_minimize: false,
      order: '-update_time',
      pagination: {
        page: 1
      }
    });
    $(element).mutation();
    actions.setState({
      editor_open: true
    }); // 恢复滚动条位置

    if (scroll_position) {
      window.scrollTo(0, scroll_position);
      scroll_position = 0;
    } // 绑定加载更多


    $window.on('scroll', actions.infiniteLoad);
    $window.on('resize', $.throttle(initSearchBar, 500));
    actions.loadTopic();
    actions.loadData();
  },

  /**
   *  初始化问题列表和问题类型
   */
  loadData: isInfiniteLoad => (state, actions) => {
    actions.setState({
      loading: true
    });
    const params = {};

    if (state.topic_id) {
      params.topic_id = state.topic_id;
    }

    params.page = state.pagination.page;

    if (!isInfiniteLoad) {
      params.page = 1;
    }

    params.order = state.order;
    params.include = ['user', 'topics'];
    getQuestions(params).then(data => {
      if (isInfiniteLoad) {
        // 如果是下拉加载就push进原来的列表
        // data.data.forEach((item) => {
        //   state.question.push(item);
        // });
        const infiniteQuestionList = state.question.concat(data.data);
        actions.setState({
          question: infiniteQuestionList,
          pagination: data.pagination,
          loading: false
        });
      } else {
        actions.setState({
          question: data.data,
          pagination: data.pagination,
          loading: false
        });
      } // 若第一页没有填满屏幕，则加载第二页


      setTimeout(() => {
        actions.infiniteLoad();
      }); // 缓存列表

      window.localStorage.setItem('questionList', JSON.stringify(window.app.getState().index.question));
    }).finally(() => {
      actions.setState({
        loading: false
      });
    }).catch(apiCatch);
  },

  /**
   * 加载问题分类
   */
  loadTopic: () => (state, actions) => {
    getTopics().then(data => {
      const topics = data.data.filter(item => item.question_count > 0);
      topics.unshift({
        topic_id: 0,
        name: '全部分类'
      });
      actions.setState({
        topic_id: 0,
        topics
      });
    });
  },

  /**
   * 发送按钮
   */
  onSubmit: ({
    title,
    content
  }) => (state, actions) => {
    const {
      auto_save_key
    } = state; // 判断登录状态

    const {
      user
    } = window.app.getState().user;
    const {
      submitting
    } = state;
    title = title.trim();

    if (submitting) {
      return;
    }

    actions.setState({
      submitting: true
    });

    if (!user) {
      mdui.snackbar('请登录');
      actions.setState({
        submitting: false
      });
      return;
    }

    if (!title) {
      mdui.snackbar('问题标题不能为空');
      actions.setState({
        submitting: false
      });
      initEditor(auto_save_key);
      return;
    }

    if (!$('.editor-content[placeholder="请输入问题内容"]').find('figure').length && !$('.editor-content[placeholder="请输入问题内容"]').children().text().trim()) {
      mdui.snackbar('问题内容不能为空');
      actions.setState({
        submitting: false
      });
      initEditor(auto_save_key);
      return;
    }

    createQuestion({
      title,
      content_rendered: content
    }).then(() => {
      // 通知/publish#reviews路由需要重新请求数据
      window.app.publish.resetReviews();
      emit('pop_open', {
        title: '发送成功',
        type: 'success',
        contentHTML: `你的提问“${ellipsisTitle(title)}”审核中，是否跳转查看？`,
        cancelText: '不跳转',
        okText: '跳转',

        onCancel($pop) {
          $pop.close();
        },

        onOk($pop) {
          $pop.close();
          setTimeout(() => {
            location.actions.go(fullPath('/publish#reviews'));
          }, 100);
        }

      });
      actions.setState({
        submitting: false
      });
      initEditor(auto_save_key);
    }).catch(apiCatch).finally(() => {
      actions.setState({
        submitting: false
      });
    });
  },

  /**
   * 问题分类
   */
  changeQuestionCategoryOrder: ({
    id
  }) => (state, actions) => {
    actions.setState({
      topic_id: id,
      pagination: {
        page: 1
      }
    });
    actions.loadData();
  },

  /**
   * 最新问题
   */
  changeQuestionOrder: ({
    id
  }) => (state, actions) => {
    actions.setState({
      order: id,
      pagination: {
        page: 1
      }
    });
    actions.loadData();
  },

  /**
   *  下拉加载更多
   */
  infiniteLoad: () => (state, actions) => {
    if (state.loading) {
      return;
    }

    const {
      pagination
    } = state;

    if (pagination.page >= pagination.pages) {
      return;
    }

    if (document.body.scrollHeight - window.pageYOffset - window.innerHeight > 100) {
      return;
    }

    const currentPagination = {
      page: pagination.page + 1
    };
    actions.setState({
      loading: true,
      pagination: currentPagination
    });
    actions.loadData(true);
  },
  onDestroy: () => {
    $window.off('scroll');
    $window.off('resize');
  }
};
export default extend(as, commonActions, userPopoverActions, editorActions);