import { h } from 'hyperapp';
import cc from 'classcat';
import mdui from 'mdui';
import $ from 'mdui.jq';
import './index.less';

const PerPageItem = ({
  pageValue,
  isActive,
  onClick
}) => h("button", {
  type: "button",
  class: cc(['mdui-btn', 'mdui-text-color-theme-text', {
    'mdui-btn-active': isActive
  }]),
  onclick: () => onClick(pageValue)
}, pageValue);

const OrderItem = ({
  toOrder,
  onClick,
  isCheck
}) => h("li", {
  class: "mdui-menu-item"
}, h("a", {
  href: "javascript:;",
  onclick: () => onClick(toOrder.value)
}, h("i", {
  class: "mdui-menu-item-icon mdui-icon material-icons"
}, isCheck ? 'check' : ''), toOrder.name));
/**
 * 分页组件
 * @param {Components.PaginationComponentProps} param0
 */


export default (({
  onPageChange,
  currentPage,
  perPage,
  totalPage,
  total,
  loading,
  extraMenu
}) => {
  currentPage = Math.max(1, currentPage);
  currentPage = Math.min(totalPage, currentPage);
  const canClickPrevious = !loading && currentPage > 1;
  const canClickNext = !loading && currentPage < totalPage;
  const pageMenuId = `pagination-setting-menu-${extraMenu ? extraMenu.menuId : ''}`;
  return h("div", {
    class: "mc-pagination"
  }, h("span", {
    class: "label mdui-text-color-theme-secondary"
  }, "\u7B2C ", Math.max(0, (currentPage - 1) * perPage + 1), " -", ' ', currentPage * perPage, " \u884C\uFF0C \u5171 ", total, " \u884C"), h("button", {
    class: "prev mdui-btn mdui-btn-icon",
    title: !canClickPrevious ? '' : '上一页',
    disabled: !canClickPrevious,
    onclick: () => onPageChange(currentPage - 1)
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "chevron_left")), h("button", {
    class: "next mdui-btn mdui-btn-icon",
    title: !canClickNext ? '' : '下一页',
    disabled: !canClickNext,
    onclick: () => onPageChange(currentPage + 1)
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "chevron_right")), extraMenu ? h("button", {
    id: "pagination-setting-menu-trigger",
    class: "mdui-btn mdui-btn-icon",
    "mdui-tooltip": JSON.stringify(extraMenu.tooltip),
    oncreate: element => {
      const menu = new mdui.Menu(element, `#${pageMenuId}`, {
        covered: false
      });
      $(element).data('menu-instance', menu);
    }
  }, h("i", {
    class: "mdui-icon material-icons"
  }, "more_vert")) : null, extraMenu ? h("div", {
    class: "mdui-menu menu",
    id: pageMenuId
  }, extraMenu.pageOptions ? h("div", null, h("div", {
    class: "label mdui-text-color-theme-secondary"
  }, "\u6BCF\u9875\u663E\u793A\u884C\u6570"), h("div", {
    class: "mdui-btn-group"
  }, extraMenu.pageOptions.items.map(item => h(PerPageItem, {
    pageValue: item,
    isActive: item === perPage,
    onClick: extraMenu.pageOptions.onPerPageChange
  })))) : null, extraMenu.orderOptions ? h("div", null, !!extraMenu.pageOptions ? h("div", {
    class: "mdui-divider"
  }) : null, h("div", {
    class: "label mdui-text-color-theme-secondary"
  }, "\u6392\u5E8F\u65B9\u5F0F"), extraMenu.orderOptions.items.map(toOrder => h(OrderItem, {
    toOrder: toOrder,
    isCheck: toOrder.value === extraMenu.orderOptions.currentValue,
    onClick: extraMenu.orderOptions.onOrderChange
  }))) : null) : null);
});