import { h } from 'hyperapp';
import './index.less';
import cc from 'classcat';
import Header from './sub/header/view.jsx';
import SubmitButton from './sub/submitButton/view.jsx';
export default (({
  // state
  setting_open,
  setting_maximize,
  setting_minimize,
  title,
  id,
  // 方法
  cancel,
  submit,
  settingMinimize,
  settingClose,
  settingMaximize
}, children) => {
  return h("div", {
    class: cc(['setting-editor', {
      'mdui-hidden': !setting_open,
      maximize: setting_maximize,
      minimize: setting_minimize
    }]),
    id: id
  }, h(Header, {
    title: title,
    setting_maximize: setting_maximize,
    settingMinimize: settingMinimize,
    settingClose: settingClose,
    settingMaximize: settingMaximize
  }), h("div", {
    class: "setting-body"
  }, children, h(SubmitButton, {
    cancel: cancel,
    submit: submit
  })));
});