import { h } from 'hyperapp';
import { requireStatic } from '~/utils/requireStatic';
import './index.less';
/**
 * @param {Components.MenuSelectionComponentProps} params
 */

export default (({
  className = '',
  selections,
  activeId,
  key = '',
  disabled,
  position = 'auto',
  align = 'auto',
  onChange,
  iconName = 'arrow_drop_down',
  iconSize = 24,
  isSvg
}) => {
  const activeSelction = selections.find(item => item.id === activeId);
  return h("div", {
    className: `xk-menu-selection xk-font ${className}`
  }, h("button", {
    class: "mdui-btn xk-button mdui-btn-dense xk-font",
    "mdui-menu": JSON.stringify({
      target: `#mc-list-header-${key}`,
      align,
      position
    }),
    disabled: disabled
  }, activeSelction ? activeSelction.text : '请选择', !isSvg ? h("i", {
    class: "mdui-icon mdui-icon-right material-icons mdui-text-color-theme-icon",
    style: `font-size: ${iconSize}px;`
  }, iconName) : null, isSvg ? h("img", {
    class: "mdui-icon mdui-icon-right material-icons mdui-text-color-theme-icon",
    src: activeSelction.text.indexOf('降序') > 0 ? requireStatic(require('../../svg/drop.svg')) : requireStatic(require('../../svg/litre.svg'))
  }) : null), h("ul", {
    class: "mdui-menu xk-font xk-menu",
    id: `mc-list-header-${key}`
  }, selections.map(item => h("li", {
    class: "mdui-menu-item",
    onclick: () => onChange(item)
  }, h("a", {
    className: `xk-font ${item.id === activeId ? 'selected' : ''}`,
    href: "javascript:void(0)"
  }, item.text)))));
});